import { FC, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  SourceByIdQuery,
  TagEntity,
  useAddTagToSourceMutation,
  useRemoveTagFromSourceMutation,
  useSourceByIdQuery,
  useSuppliersListQuery
} from '../../graphql/schema'
import { sourceCategoryDict } from '../../utils/dictionaries'
import { ReactComponent as SupplierIcon } from '../../svg/ui/supplier.svg'
import SourceForm from '../Sources/SourceForm'
import Modal from '../../components/Modal'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import useAccessCheck from '../../hooks/useAccessCheck'
import CopyInn from '../../components/CopyInn'
import useNodes from '../../hooks/useNodes'
import { dateFormatter } from '../../utils/dateFormatter'
import Tags from '../../components/Tags'
import { NonUndefined } from 'react-hook-form'
import {
  EntityBar,
  EntityBarItem,
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import PrimaryButton from '../../components/UI/PrimaryButton'
import PrimaryButtonLink from '../../components/UI/PrimaryButtonLink'
import { EntityPage } from '../../components/EntityPage/EntityPage'

type SourceType = NonUndefined<SourceByIdQuery['source']>

const Source: FC = () => {
  const { id } = useParams<'id'>()
  const { data, refetch, loading } = useSourceByIdQuery({ variables: { id: `${id}` }, skip: !id })
  const source = data?.source
  const tags = useNodes(source?.tags?.edges)

  const { data: suppliersData } = useSuppliersListQuery({
    variables: {
      query: source?.company?.inn
    },
    skip: !source?.company?.inn
  })
  const supplierId = useNodes(suppliersData?.supplierCompanies?.edges)[0]?._id

  const hasAccessSourceEdit = useAccessCheck('source.edit')

  const [addTagToSource] = useAddTagToSourceMutation()
  const [removeTagFromSource] = useRemoveTagFromSourceMutation()

  const [formOpen, setFormOpen] = useState(false)
  const [editEntity, setEditEntity] = useState<SourceType>()

  function openEditModal(): void {
    if (!source) return
    setEditEntity(source)
    setFormOpen(true)
  }

  const addTag = async (tagId: number) => {
    await addTagToSource({
      variables: {
        input: {
          source: `${id}`,
          tag: `${tagId}`
        }
      }
    })
  }

  const removeTag = async (tagId: number) => {
    await removeTagFromSource({
      variables: {
        input: {
          source: `${id}`,
          tag: `${tagId}`
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        removeTagFromSource: {
          __typename: 'removeTagFromSourcePayload',
          source: {
            __typename: 'Source',
            _id: parseInt(`${id}`),
            tags: {
              __typename: 'TagConnection',
              edges: tags
                .filter((tag) => tag._id !== tagId)
                .map((t) => ({
                  __typename: 'TagEdge',
                  cursor: '',
                  node: t
                }))
            }
          }
        }
      }
    })
  }

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex items-start justify-between'>
          <div>
            <EntityInfo>Источник</EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName value={source?.name} loading={loading} />
              {!!source?.company?.inn && <CopyInn inn={source?.company?.inn} />}
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            {hasAccessSourceEdit && (
              <PrimaryButton onClick={openEditModal}>
                <EditIcon title='Редактировать' />
                Редактировать
              </PrimaryButton>
            )}
            {supplierId && (
              <PrimaryButtonLink to={`/suppliers/${supplierId}`}>
                <SupplierIcon title='Перейти в поставщика' />
                Перейти в поставщика
              </PrimaryButtonLink>
            )}
          </div>
        </div>
        <div className='mb-12 flex items-center gap-x-4'>
          <div className='flex justify-end text-p200 text-grayscale-150'>
            {source?.category ? (
              <div className='block rounded-md bg-surface-secondary px-5 py-3 font-medium'>
                {sourceCategoryDict[source.category]}
              </div>
            ) : (
              hasAccessSourceEdit && (
                <button
                  className='block cursor-pointer rounded-md bg-tr-red px-5 py-3 font-medium text-dark-red'
                  onClick={openEditModal}
                >
                  Уточните категорию
                </button>
              )
            )}
          </div>
          <Tags entityType={TagEntity.Source} tags={tags} onAddTag={addTag} onRemoveTag={removeTag} />
        </div>
        <EntityBar>
          <EntityBarItem title='Компания' value={source?.company?.shortWithOpf} loading={loading} />
          <EntityBarItem
            title='Создан'
            value={source?.created && dateFormatter.format(new Date(source?.created))}
            loading={loading}
          />
          {source?.site && (
            <EntityBarItem title='Сайт' loading={loading}>
              <a href={source?.site} target='_blank' rel='noreferrer' className='hover:text-red-100'>
                {source?.site}
              </a>
            </EntityBarItem>
          )}
        </EntityBar>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Заявки' to='applications' />
          <EntityNavItem title='Сделки' to='deals' />
          <EntityNavItem title='Бонусы' to='bonuses' />
          <EntityNavItem title='Документы' to='docs' />
        </EntityNav>
      </EntityHeader>

      <EntityPage>
        <Outlet context={[data, refetch, openEditModal]} />
      </EntityPage>

      <Modal open={formOpen} setOpen={setFormOpen}>
        <div className='z-50 rounded-xl bg-white-0'>
          <SourceForm
            onDone={() => {
              refetch()
              setFormOpen(false)
            }}
            entity={editEntity}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Source
