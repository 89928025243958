import { FC, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import { ReactComponent as NewDocIcon } from '../../../svg/ui/new-doc.svg'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import EditDataProcessingConsentForm from './EditDataProcessingConsentForm.tsx'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import {
  DealStatus,
  DocumentTypeEntity,
  useBoVerificationsQuery,
  useContactApplicationsDealQuery,
  useCreateDataProcessingConsentDocxMutation
} from '../../../graphql/schema.tsx'
import useUploadDocuments from '../../../components/DocumentManager/useUploadDocuments.ts'
import Folder from '../../../components/DocumentManager/Folder.tsx'
import Document, { IDocument } from '../../../components/DocumentManager/Document.tsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import { getDateFormattedInDocumentName, getDateFormattedString } from '../../../utils/contractUtils.ts'
import { formatPassportNumber } from '../../../utils/formatPassportNumber.ts'
import { formatPassportCode } from '../../../utils/formatPassportCode.ts'
import downloadFile from '../../../utils/downloadFileServerless.ts'
import useNodes from '../../../hooks/useNodes.ts'

type DataProcessingConsentProps = {
  contactId: string
  canEdit: boolean
  date?: string
  documents: IDocument[]
}

const DataProcessingConsent: FC<DataProcessingConsentProps> = ({ contactId, canEdit, date, documents }) => {
  const [loading, setLoading] = useState(false)
  const [dataProcessingConsentFormOpen, setDataProcessingConsentFormOpen] = useState(false)
  const [previewDocs, setPreviewDocs] = useState<IDocument[]>([])

  const [createDataProcessingConsent] = useCreateDataProcessingConsentDocxMutation()

  const uploadDocuments = useUploadDocuments({
    entityId: contactId,
    entityType: DocumentTypeEntity.Contact,
    folderName: 'Согласие на обработку персональных данных'
  })

  const { data } = useBoVerificationsQuery({
    variables: { contactId: parseInt(contactId), states: ['completed', 'rejected', 'accepted'] },
    skip: !contactId
  })
  const boVerification = data?.boVerifications[0]
  const passportArr = formatPassportNumber(boVerification?.personInfo?.passportSeriesAndNumber || '').split(' ')

  const { data: contactApplications } = useContactApplicationsDealQuery({
    variables: { contactId: contactId },
    skip: !contactId
  })
  const dealsList = useNodes(contactApplications?.contact?.applications?.edges).map((a) => a.deal)

  const handleCreateDataProcessingConsent = async () => {
    if (loading) return

    setLoading(true)

    try {
      const createDataProcessingConsentResult = await createDataProcessingConsent({
        variables: {
          input: {
            fio: boVerification?.personInfo?.fullName,
            birthDate: getDateFormattedString(new Date(boVerification?.personInfo?.birthDate)),
            birthPlace: boVerification?.personInfo?.birthPlace,
            address: boVerification?.personInfo?.regAddress,
            passport: `${passportArr[0]} №${passportArr[1]} выдан ${boVerification?.personInfo?.passportIssuedBy}, ${getDateFormattedString(new Date(boVerification?.personInfo?.passportIssueDate))}, код ${formatPassportCode(boVerification?.personInfo?.passportIssueId || '')}`
          }
        }
      })

      const dataProcessingConsentUrl = createDataProcessingConsentResult?.data?.createDataProcessingConsent?.url
      if (!dataProcessingConsentUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(
        dataProcessingConsentUrl,
        `${getDateFormattedInDocumentName(new Date())} Согласие на обработку ПДн персональное.docx`
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const hasActualDeal = dealsList.some(
    (deal) =>
      deal?.status !== DealStatus.Terminated &&
      deal?.status !== DealStatus.Cancelled &&
      deal?.status !== DealStatus.Closed
  )
  let expiresAt
  if (date && !hasActualDeal) {
    expiresAt = new Date(date)
    expiresAt.setMonth(expiresAt.getMonth() + 6)
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Согласие на обработку персональных данных</CardTitle>
          {canEdit && (
            <CardMenu>
              <CardIconButton
                onClick={() => {
                  setPreviewDocs(documents)
                  setDataProcessingConsentFormOpen(true)
                }}
              >
                <EditIcon />
              </CardIconButton>
            </CardMenu>
          )}
        </CardHeader>

        <div className='px-5 pb-5'>
          <div className='rounded-md bg-surface-primary shadow-card'>
            <table className='w-full border-b-1 border-grayscale-400'>
              <tbody>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-md last:rounded-r-md'>Дата согласия</td>
                  <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                    {date
                      ? dateFormatter.format(new Date(date))
                      : canEdit && (
                          <button
                            className='cursor-pointer text-red-50 hover:text-red-100'
                            onClick={() => {
                              setPreviewDocs(documents)
                              setDataProcessingConsentFormOpen(true)
                            }}
                          >
                            Добавить дату
                          </button>
                        )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className='px-5 pb-5'>
              <Folder onUpload={uploadDocuments} limit={0} canDelete={true} canUpload={true}>
                <div className='mt-10 grid grid-cols-2 gap-x-20 gap-y-10 px-10 tabular-nums empty:hidden'>
                  {documents?.map((doc) => (
                    <Document
                      key={doc._id}
                      doc={doc}
                      canDelete={canEdit}
                      onPreviewClick={() => {
                        setPreviewDocs([doc])
                        setDataProcessingConsentFormOpen(true)
                      }}
                    />
                  ))}
                </div>
              </Folder>
            </div>

            <div className='flex w-full justify-center pb-8 pt-2'>
              <button
                className='flex items-center gap-4 text-p200 text-labels-secondary'
                onClick={handleCreateDataProcessingConsent}
              >
                {loading ? (
                  <LoadingIcon className='animate-spin' />
                ) : (
                  <NewDocIcon
                    title='Создать согласие на обработку
                персональных данных'
                  />
                )}
                Создать согласие на обработку персональных данных
              </button>
            </div>
          </div>

          {date && expiresAt && expiresAt < new Date() && (
            <div className='mt-6 text-p200 text-base-red'>
              Согласие на получение отчета БКИ просрочено {dateFormatter.format(new Date(expiresAt))}
            </div>
          )}
        </div>
      </Card>

      <SuspenseModal open={dataProcessingConsentFormOpen} setOpen={setDataProcessingConsentFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <EditDataProcessingConsentForm
            contactId={contactId}
            date={date}
            onDone={() => setDataProcessingConsentFormOpen(false)}
            docs={previewDocs}
          />
        </div>
      </SuspenseModal>
    </>
  )
}

export default DataProcessingConsent
