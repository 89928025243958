import { FC, useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as MoreIcon } from '../../svg/icons/more.svg'
import { ReactComponent as WarningIcon } from '../../svg/icons/warning.svg'
import { ReactComponent as LoadingIcon } from '../../svg/ui/refresh.svg'
import { ReactComponent as StatusPendingIcon } from '../../svg/icons/appScoringStatusPending.svg'
import { Menu, MenuButton, Transition } from '@headlessui/react'
import {
  AccreditationStatus,
  SupplierByIdQuery,
  User,
  useUpdateSupplierCompanyMutation
} from '../../graphql/schema.tsx'
import c from 'clsx'
import { useWindowEvent } from '../../hooks/useWindowEvent.ts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils.ts'
import { dateTimeFormatter } from '../../utils/dateFormatter.ts'
import Textarea from '../../components/Textarea.tsx'
import SubmitButton from '../../components/SubmitButton/index.tsx'
import getFullName from '../../utils/getFullName.ts'
import useAccessCheck from '../../hooks/useAccessCheck.ts'
import { companyAccreditationStatusDict } from '../../utils/dictionaries.ts'
import Tooltip from '../../components/Tooltip.tsx'
import {
  companyAccreditationColorDict,
  companyAccreditationIconColorDict,
  companyAccreditationTextColorDict
} from './models'
import AccreditationStatusIcon from './AccreditationStatusIcon.tsx'
import { Card, CardHeader, CardTitle } from '../../components/Card.tsx'
import { showPopup } from '../../components/Toaster/showPopup.tsx'

interface AccreditationStatusSwitcherProps {
  supplier: SupplierByIdQuery['supplierCompany']
  onDone: () => void
}

const supplierDoneStatusIcon = (status?: AccreditationStatus) => {
  if (status === AccreditationStatus.Accepted) return true
  if (status === AccreditationStatus.Rejected) return false
  else return undefined
}

const listStatuses: AccreditationStatus[] = [
  AccreditationStatus.Requested,
  AccreditationStatus.InProgress,
  AccreditationStatus.Accepted,
  AccreditationStatus.Rejected
]

const AccreditationStatusSwitcher: FC<AccreditationStatusSwitcherProps> = ({ supplier, onDone }) => {
  const [accreditationStatus, setAccreditationStatus] = useState<AccreditationStatus | undefined>(
    supplier?.accreditationStatus
  )
  const [formElement, setFormElement] = useState<HTMLFormElement | null>(null)
  const [editBtnElement, setEditBtnElement] = useState<HTMLButtonElement | null>(null)
  const [openCommentForm, setOpenCommentForm] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors }
  } = useForm<{ accreditationComment: string }>()

  useEffect(() => {
    if (supplier?.accreditationComment) {
      setValue('accreditationComment', supplier.accreditationComment)
    }
    if (supplier?.accreditationStatus) {
      setAccreditationStatus(supplier.accreditationStatus)
    }
  }, [supplier?.accreditationComment, supplier?.accreditationStatus, setValue])

  // делаем неактивным поле с комментарием, когда пользователь кликает вне этого поля
  useWindowEvent('pointerdown', (event) => {
    if (!openCommentForm) return
    if (formElement?.contains(event.target as Node)) return
    if (editBtnElement?.contains(event.target as Node)) return
    setOpenCommentForm(false)
    event.preventDefault()
  })

  const hasAccessAccreditationDecision = useAccessCheck('supplier_company.accreditation.decision')

  const [updateSupplier, { loading }] = useUpdateSupplierCompanyMutation()

  async function changeAccreditationStatus(status: AccreditationStatus) {
    await updateSupplier({
      variables: {
        input: {
          id: supplier?._id.toString() as string,
          accreditationStatus: status
        }
      }
    })
      .then(() => onDone())
      .catch((error) => {
        if (error.message.includes('Accreditation status is already in progress')) {
          showPopup({
            title: 'Ошибка',
            subtitle: 'Процесс аккредитации уже запущен'
          })
          onDone()
        } else {
          console.error(error)
        }
      })
  }

  const onSubmit: SubmitHandler<{ accreditationComment: string }> = async (data: { accreditationComment: string }) => {
    if (!data || !supplier) return

    await updateSupplier({
      variables: {
        input: {
          id: supplier?._id.toString(),
          accreditationComment: data.accreditationComment
        }
      }
    })
      .then(() => {
        setOpenCommentForm(false)
        onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<{ accreditationComment: string }>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  const canAccept = !!supplier?.leasingSubjectCategories?.edges?.length

  if (!supplier) return null

  return (
    <Card>
      <CardHeader>
        <CardTitle>Аккредитация поставщика</CardTitle>
      </CardHeader>
      <div className='px-5 pb-5'>
        <div className='flex w-full items-center justify-between rounded-md bg-surface-primary p-5 text-grayscale-150 shadow-card'>
          <Menu>
            Состояние
            {supplier?.accreditationStatus === AccreditationStatus.Pending ? (
              <button
                type='button'
                className='flex h-16 max-w-max items-center gap-3 rounded-md bg-white-0 px-5 text-grayscale-150 shadow-xs hover:bg-grayscale-450'
                onClick={async () => {
                  setAccreditationStatus(AccreditationStatus.Requested)
                  await changeAccreditationStatus(AccreditationStatus.Requested)
                }}
              >
                {loading ? <LoadingIcon className='w-8 animate-spin' /> : <StatusPendingIcon />}
                Отправить на аккредитацию
              </button>
            ) : (
              <div className='relative z-20'>
                <MenuButton
                  className={c(
                    companyAccreditationColorDict[accreditationStatus as AccreditationStatus],
                    companyAccreditationTextColorDict[accreditationStatus as AccreditationStatus],
                    'ml-10 flex h-16 items-center gap-3 rounded-md px-3',
                    !hasAccessAccreditationDecision && 'cursor-not-allowed'
                  )}
                  disabled={!hasAccessAccreditationDecision}
                >
                  <AccreditationStatusIcon
                    maxSegments={2}
                    activeSegments={
                      Object.keys(companyAccreditationStatusDict).findIndex(
                        (status) => status === accreditationStatus
                      ) + 1
                    }
                    isDone={supplierDoneStatusIcon(accreditationStatus)}
                    bg={true}
                  />
                  {companyAccreditationStatusDict[accreditationStatus as AccreditationStatus]}
                  <MoreIcon className='rotate-90' height={15} width={15} />
                </MenuButton>
                <Transition
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute ml-10 mt-3 min-w-[200px] rounded-md border-[1px] border-grayscale-400 bg-surface-primary'>
                    {listStatuses?.map((status) => {
                      return (
                        <Menu.Item key={status}>
                          <div
                            aria-disabled={status === AccreditationStatus.Accepted && !canAccept}
                            className={c(
                              'flex cursor-pointer items-center gap-3 px-5 py-2 first:rounded-t-md first:pt-3 last:rounded-b-md last:pb-3 hover:bg-grayscale-450',
                              status === accreditationStatus && 'pointer-events-none text-base-red'
                            )}
                            onClick={async () => {
                              setAccreditationStatus(status)
                              setOpenCommentForm(true)
                              await changeAccreditationStatus(status)
                            }}
                          >
                            <div className={c(companyAccreditationIconColorDict[status as AccreditationStatus], 'p-2')}>
                              <AccreditationStatusIcon
                                maxSegments={2}
                                activeSegments={
                                  Object.keys(companyAccreditationStatusDict).findIndex((s) => s === status) + 1
                                }
                                isDone={supplierDoneStatusIcon(status)}
                              />
                            </div>
                            {companyAccreditationStatusDict[status]}

                            {!canAccept && status === AccreditationStatus.Accepted && (
                              <Tooltip className='ml-auto' target={<WarningIcon className='text-red-100' />}>
                                <div className='text-center'>Заполните категории поставщика</div>
                              </Tooltip>
                            )}
                          </div>
                        </Menu.Item>
                      )
                    })}
                  </Menu.Items>
                </Transition>
              </div>
            )}
          </Menu>
          {supplier?.accreditationCompletedBy &&
            accreditationStatus &&
            [AccreditationStatus.Accepted, AccreditationStatus.Rejected].includes(accreditationStatus) && (
              <div className='ml-10 mr-2 italic'>
                {getFullName(supplier?.accreditationCompletedBy as User)}{' '}
                {supplier?.accreditationCompletedAt &&
                  dateTimeFormatter.format(new Date(supplier?.accreditationCompletedAt))}
              </div>
            )}
          {supplier?.accreditationStatusChangedBy && accreditationStatus === AccreditationStatus.InProgress && (
            <div className='ml-10 mr-2 italic'>
              {getFullName(supplier?.accreditationStatusChangedBy as User)}{' '}
              {supplier?.accreditationStatusChangedAt &&
                dateTimeFormatter.format(new Date(supplier.accreditationStatusChangedAt))}
            </div>
          )}
        </div>
      </div>
      {hasAccessAccreditationDecision
        ? accreditationStatus &&
          [AccreditationStatus.Accepted, AccreditationStatus.Rejected].includes(accreditationStatus) && (
            <>
              <div className='mb-6 flex'>
                <h3 className='text-h250 pl-6 font-display'>Комментарий</h3>
                {hasAccessAccreditationDecision && (
                  <button
                    ref={setEditBtnElement}
                    className='flex items-center text-grayscale-200 hover:text-red-100'
                    onClick={() => setOpenCommentForm(!openCommentForm)}
                  >
                    <EditIcon className='ml-5 h-[18px] w-[18px]' title='Редактировать' />
                  </button>
                )}
              </div>
              {openCommentForm ? (
                <form ref={setFormElement} onSubmit={handleSubmit(onSubmit)} className='mb-10 px-6'>
                  <Textarea {...register('accreditationComment')} error={errors.accreditationComment} />
                  <div className='mt-10 max-w-[250px]'>
                    <SubmitButton loading={loading}>Сохранить</SubmitButton>
                  </div>
                </form>
              ) : (
                supplier?.accreditationComment && (
                  <h3 className='mx-5 mb-10 rounded-md bg-surface-primary p-6 shadow-card'>
                    {supplier?.accreditationComment}
                  </h3>
                )
              )}
            </>
          )
        : supplier?.accreditationStatus !== AccreditationStatus.Pending && (
            <>
              <div className='mb-6 flex items-center justify-between rounded-xl bg-grayscale-450 p-6'>
                <p>Решение</p>
                <p
                  className={(() => {
                    switch (supplier?.accreditationStatus) {
                      case AccreditationStatus.Accepted:
                        return 'text-[#62B85A]'
                      case AccreditationStatus.Rejected:
                        return 'text-red-150'
                      case AccreditationStatus.Requested:
                        return 'text-blue-700'
                      case AccreditationStatus.InProgress:
                        return 'text-cyan-600'
                    }
                  })()}
                >
                  {companyAccreditationStatusDict[supplier?.accreditationStatus || AccreditationStatus.Requested]}
                </p>
              </div>
              {supplier?.accreditationComment && <h3 className='mb-6 px-6'>{supplier?.accreditationComment}</h3>}
            </>
          )}
    </Card>
  )
}

export default AccreditationStatusSwitcher
