import { FC, useMemo } from 'react'
import { SpecItem } from '../graphql/schema'
import { GridTable, GridTableCell, GridTableRow } from './Table'
import { currencyDict, formatDecimal } from '../utils/formatNumber'
import { getSpecTotals } from '../containers/Deal/Supplies/parseSpec'

interface SpecificationProps {
  items?: SpecItem[]
  expectedTotalVATCents?: number
  expectedTotalCents?: number
  currency: string
}

const Specification: FC<SpecificationProps> = ({ items, currency, expectedTotalCents, expectedTotalVATCents }) => {
  const totals = useMemo(() => {
    return getSpecTotals(items || [])
  }, [items])

  return (
    <GridTable className='grid-cols-[auto_1fr_auto_auto_auto_auto_auto]'>
      <GridTableRow>
        <GridTableCell className='text-labels-secondary'>№</GridTableCell>
        <GridTableCell className='text-labels-secondary'>Наименование</GridTableCell>
        <GridTableCell className='text-labels-secondary'>Кол-во</GridTableCell>
        <GridTableCell className='whitespace-nowrap text-labels-secondary'>
          Цена за ед. с НДС {currencyDict[currency]}
        </GridTableCell>
        <GridTableCell className='whitespace-nowrap text-labels-secondary'>
          НДС в сумме {currencyDict[currency]}
        </GridTableCell>
        <GridTableCell className='whitespace-nowrap text-labels-secondary'>
          Сумма с НДС {currencyDict[currency]}
        </GridTableCell>
      </GridTableRow>
      {items?.map((entry, index) => (
        <GridTableRow key={index}>
          <GridTableCell className='font-mono'>{index + 1}</GridTableCell>
          <GridTableCell>{entry.name}</GridTableCell>
          <GridTableCell className='text-right font-mono'>
            {entry.count} {entry.unit}
          </GridTableCell>
          <GridTableCell className='text-right font-mono'>{formatDecimal(entry.pricePerUnitCents)}</GridTableCell>
          <GridTableCell className='text-right font-mono'>{formatDecimal(entry.VATCents)}</GridTableCell>
          <GridTableCell className='text-right font-mono'>{formatDecimal(entry.totalPriceCents)}</GridTableCell>
        </GridTableRow>
      ))}
      <GridTableRow>
        <GridTableCell className='col-span-4 text-right font-medium'>Итого:</GridTableCell>
        <GridTableCell className='text-right font-mono font-medium'>
          {expectedTotalVATCents ? formatDecimal(expectedTotalVATCents) : formatDecimal(totals?.totalVat)}
          {/* TODO: show warning if expected does not match calculated */}
        </GridTableCell>
        <GridTableCell className='text-right font-mono font-medium'>
          {expectedTotalCents ? formatDecimal(expectedTotalCents) : formatDecimal(totals?.totalAmount)}
          {/* TODO: show warning if expected does not match calculated */}
        </GridTableCell>
      </GridTableRow>
    </GridTable>
  )
}

export default Specification
