import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import { FC } from 'react'
import { ReactComponent as MoreIcon } from '../svg/icons/more.svg'
import { ReactComponent as TelegramIcon } from '../svg/icons/telegram.svg'
import { ReactComponent as WhatsappIcon } from '../svg/icons/whatsapp.svg'
import { ReactComponent as PhoneIcon } from '../svg/icons/phone.svg'
import { ReactComponent as CopyIcon } from '../svg/icons/copy.svg'
import { ReactComponent as EnvelopeIcon } from '../svg/icons/envelope.svg'
import copyToClipboard from 'copy-to-clipboard'
import { showFizz } from './Fizz'
import Avatar from './Avatar'
import getFullName from '../utils/getFullName.ts'
import { useUserByIdQuery } from '../graphql/schema.tsx'

interface ContactUserProps {
  userId?: number
}

const ContactUser: FC<ContactUserProps> = ({ userId }) => {
  const { data } = useUserByIdQuery({
    variables: {
      id: `${userId}`
    },
    skip: !userId
  })
  const user = data?.user
  const number = user?.phone
  const email = user?.email

  return (
    <Menu>
      <div className='relative'>
        <MenuButton className='flex items-center'>
          <Avatar width='w-8' height='h-8' className='mr-3' userId={user?._id} />
          {getFullName(user)}
          {number && <MoreIcon className='ml-2 rotate-90' height='14px' width='14px' />}
        </MenuButton>
        <Transition
          as='div'
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <MenuItems className='bg-white absolute z-50 mt-2 flex w-[240px] flex-col gap-6 rounded-lg bg-white-0 p-7 text-p100 shadow-lg focus:outline-none'>
            <a href={`https://t.me/${number}`} target='_blank' rel='noreferrer' className='flex items-center gap-6'>
              <TelegramIcon width={24} height={24} />
              <span className='text-base-black hover:text-base-red'>Написать в Telegram</span>
            </a>
            <a href={`https://wa.me/${number}`} target='_blank' rel='noreferrer' className='flex items-center gap-6'>
              <WhatsappIcon width={24} height={24} />
              <span className='text-base-black hover:text-base-red'>Написать в WhatsApp</span>
            </a>
            <a href={`mailto:${email}`} target='_blank' rel='noreferrer' className='flex items-center gap-8'>
              <EnvelopeIcon width={20} height={20} />
              <span className='text-base-black hover:text-base-red'>Написать на Email</span>
            </a>
            <a href={`tel:${number}`} target='_blank' rel='noreferrer' className='flex items-center gap-8'>
              <PhoneIcon width={20} height={20} />
              <span className='text-base-black hover:text-base-red'>Позвонить</span>
            </a>
            <div
              className='flex cursor-pointer items-center gap-8'
              onClick={async (e) => {
                if (!number) return
                copyToClipboard(number)
                showFizz('Скопировано', e.clientX, e.clientY)
              }}
            >
              <CopyIcon width={20} height={20} />
              <span className='text-base-black hover:text-base-red'>Скопировать</span>
            </div>
          </MenuItems>
        </Transition>
      </div>
    </Menu>
  )
}

export default ContactUser
