import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../Card'
import { ReactComponent as RotateLeftIcon } from '../../svg/icons/rotateLeft.svg'
import { ReactComponent as RotateRightIcon } from '../../svg/icons/rotateRight.svg'
import { ReactComponent as RotateFlipIcon } from '../../svg/icons/rotateFlip.svg'
import { ReactComponent as LoupMinusIcon } from '../../svg/icons/loupMinus.svg'
import { ReactComponent as LoupPlusIcon } from '../../svg/icons/loupPlus.svg'
import { ReactComponent as RefreshIcon } from '../../svg/icons/refresh-slim.svg'
import { ReactComponent as ChevronRight } from '../../svg/ui/chevron-right.svg'
import { IDocument } from '../DocumentManager/Document'
import { AttachmentCategory } from '../../graphql/schema'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { APIRoot, APIVerificationRoot } from '../../utils/APIRoot'
import { Matrix, useTransformMatrix, matrixToCssString } from '../../hooks/useTransformMatrix'
import useSuspenseFile from '../../hooks/useSuspenseFile'
import PDFPage from './Files/PDFPage'

interface PreviewerProps {
  doc: IDocument & { category?: AttachmentCategory | undefined }
  source: 'backend' | 'verification'
}

interface PreviewContentProps {
  url: string
  transform: Matrix
  mimeType?: string
  fileName?: string
  setPagesCount: (count: number) => void
  page: number
}

const ImagePreview: FC<{ file: File; transform: Matrix }> = ({ file, transform }) => {
  const dataUrl = useMemo(() => {
    if (!file) return null
    return URL.createObjectURL(file)
  }, [file])

  useEffect(() => {
    return () => {
      if (!dataUrl) return
      // release memory when url is not needed any more
      URL.revokeObjectURL(dataUrl)
    }
  }, [dataUrl])

  return (
    <img
      style={{ transform: matrixToCssString(transform) }}
      className='w-full object-contain'
      src={dataUrl as string}
      alt={file?.name}
    />
  )
}

const PreviewContent: FC<PreviewContentProps> = ({ url, transform, mimeType, setPagesCount, page }) => {
  const file = useSuspenseFile(url, 'GET', mimeType)

  if (!file) return <div className='px-7 py-4'>Файл не найден на сервере, ${url}</div>

  switch (mimeType || file.type) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return <ImagePreview file={file} transform={transform} />
    case 'application/pdf':
      return <PDFPage file={file} transform={transform} setPagesCount={setPagesCount} page={page} />
    default:
      return <div className='px-7 py-4'>Формат {mimeType} пока не поддерживается</div>
  }
}

const Previewer: FC<PreviewerProps> = ({ doc, source = 'backend' }) => {
  const {
    transform,
    wrapperRef,
    rotateClockwise,
    rotateCounterClockwise,
    zoomIn,
    zoomOut,
    flipHorizontal,
    resetTransform
  } = useTransformMatrix({
    scaleStep: 0.2
  })

  const [pagesCount, setPagesCount] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const nextPage = useCallback(() => setCurrentPage((prev) => Math.min(prev + 1, pagesCount)), [pagesCount])
  const prevPage = useCallback(() => setCurrentPage((prev) => Math.max(prev - 1, 1)), [])

  const baseURL = source === 'backend' ? APIRoot : APIVerificationRoot

  return (
    <Card>
      <CardHeader>
        <CardTitle>{doc.originalName}</CardTitle>
        {pagesCount > 1 && (
          <CardMenu className='mx-auto'>
            <CardIconButton type='button' onClick={prevPage}>
              <ChevronRight className='rotate-180' />
            </CardIconButton>
            <div className='tabular-nums text-labels-secondary'>
              {currentPage} / {pagesCount}
            </div>
            <CardIconButton type='button' onClick={nextPage}>
              <ChevronRight />
            </CardIconButton>
          </CardMenu>
        )}
        <CardMenu>
          <CardIconButton type='button' onClick={rotateCounterClockwise}>
            <RotateLeftIcon />
          </CardIconButton>
          <CardIconButton type='button' onClick={flipHorizontal}>
            <RotateFlipIcon />
          </CardIconButton>
          <CardIconButton type='button' onClick={rotateClockwise}>
            <RotateRightIcon />
          </CardIconButton>
          <CardIconButton type='button' onClick={zoomOut}>
            <LoupMinusIcon />
          </CardIconButton>
          <CardIconButton type='button' onClick={zoomIn}>
            <LoupPlusIcon />
          </CardIconButton>
          <CardIconButton
            type='button'
            onClick={() => {
              resetTransform()
            }}
          >
            <RefreshIcon />
          </CardIconButton>
        </CardMenu>
      </CardHeader>

      <div className='px-5 pb-5'>
        <div className='relative max-w-full overflow-hidden rounded-md bg-surface-primary shadow-card'>
          <div className='cursor-grab touch-none' ref={wrapperRef}>
            <PreviewContent
              transform={transform}
              url={baseURL + doc?.filePath}
              fileName={doc?.originalName}
              mimeType={doc.mimetype}
              setPagesCount={setPagesCount}
              page={currentPage}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Previewer
