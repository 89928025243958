import { FC, useEffect, useRef } from 'react'
import { getDocument, PDFDocumentLoadingTask, PDFPageProxy } from 'pdfjs-dist'
import { suspend } from 'suspend-react'
import { Matrix, matrixToCssString } from '../../../hooks/useTransformMatrix'

interface PDFPageProps {
  file: File
  page?: number
  transform: Matrix
  setPagesCount: (count: number) => void
}

const PDFPage: FC<PDFPageProps> = ({ file, page = 1, transform, setPagesCount }) => {
  const containerRef = useRef<HTMLCanvasElement>(null)
  const loadingTask = useRef<PDFDocumentLoadingTask>()

  const doc = suspend(async () => {
    if (loadingTask.current) await loadingTask.current.destroy()
    const buffer = await file.arrayBuffer()
    const task = getDocument(buffer)
    loadingTask.current = task
    return task.promise
  }, [file, 'preview']) // dependencies array is also a key for the cache

  useEffect(() => {
    // if component unmounts before loading is finished, cancel the loading
    return () => {
      if (loadingTask.current) loadingTask.current.destroy()
    }
  }, [])

  useEffect(() => {
    if (!doc) return
    setPagesCount(doc.numPages)
  }, [doc, setPagesCount])

  useEffect(() => {
    const canvas = containerRef.current
    if (!canvas || !doc) {
      console.error('Canvas or document is missing!')
      return
    }

    let canceled = false // Flag to cancel rendering if the component unmounts

    // Render the page
    doc
      .getPage(page)
      .then((pdfPage: PDFPageProxy) => {
        if (canceled) return
        const context = canvas.getContext('2d')
        if (!context) return

        const viewport = pdfPage.getViewport({ scale: window.devicePixelRatio || 1 })
        canvas.width = viewport.width
        canvas.height = viewport.height

        pdfPage
          .render({
            canvasContext: context,
            viewport
          })
          .promise.catch((err) => {
            if (err?.name !== 'RenderingCancelledException') {
              console.error('Render Error:', err)
            }
          })
      })
      .catch((err) => {
        console.error('Error loading page:', err)
      })

    return () => {
      canceled = true
    }
  }, [doc, page])

  return <canvas ref={containerRef} style={{ transform: matrixToCssString(transform) }} className='w-full' />
}

export default PDFPage
