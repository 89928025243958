import { FC, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import downloadFile from '../../../utils/downloadFileServerless'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  isIP
} from '../../../utils/contractUtils'
import {
  PaymentKind,
  PaymentState,
  PaymentTargetType,
  useCreatePaymentScheduleNoticeDocxMutation,
  useDealForPaymentScheduleNoticeSuspenseQuery,
  useDealPaymentSchedulesQuery,
  usePaymentsLazyQuery
} from '../../../graphql/schema'
import { AddressData, CompanyData } from '../../../types/dadata'
import Input from '../../../components/Input'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import SubmitButton from '../../../components/SubmitButton/index'
import { formatDecimal } from '../../../utils/formatNumber'
import Suggestions from '../../../components/Suggestions.tsx'
import { useAddressSuggestions } from '../../../hooks/useSuggestions.ts'
import useThrottledState from '../../../hooks/useThrottledState.ts'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import usePersonAddressLazy from '../../../hooks/usePersonAddressLazy.ts'

type Inputs = {
  legalAddress: string
}

interface ActReportFormProps {
  dealId: string
}

const PaymentScheduleNoticeForm: FC<ActReportFormProps> = ({ dealId }) => {
  const [loading, setLoading] = useState(false)
  const [createPaymentScheduleNotice] = useCreatePaymentScheduleNoticeDocxMutation()
  const [getPayments] = usePaymentsLazyQuery()

  const { data } = useDealForPaymentScheduleNoticeSuspenseQuery({
    variables: { id: dealId }
  })
  const { data: paymentSchedulesData } = useDealPaymentSchedulesQuery({
    variables: { dealId: dealId }
  })

  const deal = data?.deal
  const customer = deal?.customerCompany
  const companyDadata: CompanyData = useMemo(() => (customer?.dadata ? customer.dadata.data : {}), [customer?.dadata])
  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  const paymentSchedules = paymentSchedulesData?.paymentSchedules || []
  const actualSchedule = paymentSchedules[0]

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      legalAddress: companyDadata.address?.unrestricted_value
    }
  })

  const addressQuery = watch('legalAddress')
  const [throttledAddressQuery] = useThrottledState(addressQuery, 500)
  const addressSuggestions = useAddressSuggestions(throttledAddressQuery)

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (deal?.vatRate === undefined) throw new Error('vatRate not found!')

    setLoading(true)

    try {
      const { data: paymentsData } = await getPayments({
        variables: {
          kinds: [PaymentKind.Advance, PaymentKind.Leasing, PaymentKind.Redemption],
          targetType: PaymentTargetType.Deal,
          targetId: dealId.toString()
        }
      })
      const paymentsRaw =
        paymentsData?.payments?.edges
          ?.map((e) => e?.node)
          .sort((a, b) => {
            if (a?.kind === PaymentKind.Redemption && b?.kind !== PaymentKind.Redemption) {
              return 1
            } else if (b?.kind === PaymentKind.Redemption && a?.kind !== PaymentKind.Redemption) {
              return -1
            } else {
              return 0 // иначе сохраняем порядок
            }
          }) || []
      const leasingPayments = paymentsRaw?.filter((p) => p?.kind === PaymentKind.Leasing) || []

      const payments = paymentsRaw?.map((p) => ({
        amount: typeof p?.amount === 'number' ? formatDecimal(p.amount * 100) : '',
        appreciationAmount: typeof p?.fee === 'number' ? formatDecimal(p.fee * 100) : '0,00',
        date: p?.date ? dateFormatter.format(new Date(p.date)) : '',
        number:
          p?.kind === PaymentKind.Leasing
            ? `${leasingPayments?.findIndex((payment) => payment?.id === p.id) + 1}`
            : p?.kind && paymentKindDict[p.kind],
        redemptionPrice:
          typeof p?.redemption === 'number'
            ? p?.state === PaymentState.Paid
              ? '0,00'
              : formatDecimal(p.redemption * 100)
            : '0,00',
        returnAmount:
          p?.kind === PaymentKind.Advance
            ? formatDecimal(p.amount * 100)
            : typeof p?.body === 'number'
              ? formatDecimal(p.body * 100)
              : '',
        vat: deal.vatRate
          ? formatDecimal(parseFloat((((p?.amount || 0) / (100 + deal.vatRate)) * deal.vatRate).toFixed(2)) * 100)
          : 'Без НДС'
      }))
      const total = paymentsRaw?.reduce(
        (acc, item) => {
          const vat = parseFloat((((item?.amount || 0) / (100 + deal.vatRate)) * deal.vatRate).toFixed(2))
          acc.amount += item?.amount || 0
          acc.appreciationAmount += item?.fee || 0
          acc.redemptionPrice += item?.redemption || 0
          acc.returnAmount += (item?.kind === PaymentKind.Advance ? item?.amount : item?.body) || 0
          acc.vat += vat
          return acc
        },
        {
          amount: 0,
          appreciationAmount: 0,
          redemptionPrice: 0,
          returnAmount: 0,
          vat: 0
        }
      )
      payments?.push({
        amount: formatDecimal(total.amount * 100),
        appreciationAmount: formatDecimal(total.appreciationAmount * 100),
        date: 'Итого',
        number: '',
        redemptionPrice: '',
        returnAmount: formatDecimal(total.returnAmount * 100),
        vat: total?.vat > 0 ? formatDecimal(total.vat * 100) : 'Без НДС'
      })

      const createPaymentScheduleNoticeResult = await createPaymentScheduleNotice({
        variables: {
          input: {
            companyName: `${customer?.shortWithOpf ? getCompanyForm(customer.shortWithOpf, companyName) : ''} ${IP ? companyName : '«' + companyName + '»'}`,
            companyAddress: data.legalAddress,
            companyInn: customer?.inn,
            contractNumber: deal?.contractNumber,
            contractDate: deal?.contractDate ? dateFormatter.format(new Date(deal.contractDate)) : '',
            documentDate: actualSchedule?.documentDate
              ? dateFormatter.format(new Date(actualSchedule.documentDate))
              : '',
            vat: `${deal.vatRate}`,
            items: payments
          }
        }
      })

      const createPaymentScheduleNoticeUrl = createPaymentScheduleNoticeResult.data?.createPaymentScheduleNotice?.url
      if (!createPaymentScheduleNoticeUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(
        createPaymentScheduleNoticeUrl,
        `${getDateFormattedInDocumentName(new Date())} Уведомление об изменении платежа ввиду изменения ключевой ставки №УВД${deal?.contractNumber} от ${dateFormatter.format(new Date())}.docx`
      )
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }

  const [getAddress, addressLoading, addressError] = usePersonAddressLazy()

  return (
    <section className='w-[600px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Генерация уведомления об изменении графика</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12'>
          <Suggestions<AddressData>
            suggestions={addressSuggestions?.map((s: AddressData) => ({
              key: s._id,
              title: s.value,
              payload: s
            }))}
            select={(s) =>
              setValue('legalAddress', s.payload?.value || '', {
                shouldValidate: true
              })
            }
          >
            <Input
              label='Юридический адрес'
              type='text'
              autoComplete='off'
              placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
              {...register('legalAddress', { required: true })}
              error={errors.legalAddress}
            />
            {isIP(companyDadata) && (
              <>
                {addressLoading ? (
                  <span className='text-xs text-labels-tertiary'>Загрузка...</span>
                ) : addressError ? (
                  <span className='text-xs text-base-red/100'>Данные не найдены</span>
                ) : (
                  <button
                    className='w-fit flex-none text-xs text-base-red/100 underline decoration-dashed'
                    type='button'
                    onClick={async () => {
                      const address = await getAddress(customer?.inn)
                      setValue('legalAddress', address || '')
                    }}
                  >
                    Заполнить из верификации
                  </button>
                )}
              </>
            )}
          </Suggestions>
        </div>
        <SubmitButton loading={loading}>
          <FileIcon className='mr-5' />
          Сгенерировать
        </SubmitButton>
      </form>
    </section>
  )
}

export default PaymentScheduleNoticeForm
