import { FC, useRef, useEffect } from 'react'
import { GlobalWorkerOptions, getDocument, PageViewport } from 'pdfjs-dist'
import type { PDFDocumentLoadingTask, PDFPageProxy } from 'pdfjs-dist'
import pdfWorkerUrl from 'pdfjs-dist/build/pdf.worker.mjs?worker&url'
import { suspend } from 'suspend-react'

GlobalWorkerOptions.workerSrc = pdfWorkerUrl

const PDFPage: FC<{ page: PDFPageProxy; viewport: PageViewport }> = ({ page, viewport }) => {
  const containerRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!containerRef.current) return
    const canvas = containerRef.current
    const context = canvas.getContext('2d')
    if (!context) return

    canvas.width = viewport.width
    canvas.height = viewport.height
    const renderTask = page.render({
      canvasContext: context,
      viewport
    })

    return () => {
      // cancel call does not cancel it immediately
      // it can cause an error if the new render happens before the old one is ended
      // but it is not a problem in this case, the error is just annoying
      if (renderTask) renderTask.cancel()
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      renderTask.promise.catch(() => {})
    }
  }, [page, viewport])

  return <canvas className='w-full object-cover' ref={containerRef} />
}

const PDFDoc: FC<{ file: File }> = ({ file }) => {
  const loadingTask = useRef<PDFDocumentLoadingTask>()

  const [pages, viewports] = suspend(async () => {
    const pages: PDFPageProxy[] = []
    const viewports: PageViewport[] = []

    try {
      if (loadingTask.current) await loadingTask.current.destroy()
      const fileData = await file.arrayBuffer()
      const document = await getDocument(fileData).promise
      // iterate over pages and load them with promise all
      const promises = Array.from(Array(document.numPages).keys()).map(async (pageNumber) => {
        const page = await document.getPage(pageNumber + 1)
        const viewport = await page.getViewport({ scale: window.devicePixelRatio })
        pages[pageNumber] = page
        viewports[pageNumber] = viewport
      })

      await Promise.all(promises)
    } catch (error) {
      console.error(error)
    }

    return [pages, viewports] as const
  }, [file])

  useEffect(() => {
    // if component unmounts before loading is finished, cancel the loading
    return () => {
      if (loadingTask.current) loadingTask.current?.destroy()
    }
  }, [])

  return (
    <div>
      {pages.length
        ? pages.map((page, pageNumber) => <PDFPage key={pageNumber} page={page} viewport={viewports[pageNumber]} />)
        : 'Ошибка. Не удалось загрузить PDF-документ.'}
    </div>
  )
}

export default PDFDoc
