import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { SourceByIdQuery, useUpdateSourceMutation } from '../../graphql/schema'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import SubmitButton from '../../components/SubmitButton'
import parseDecimal from '../../utils/parseDecimal'
import CommissionRewardRateList from '../../components/CommissionRewardRateList'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import MaskedPercentInput from '../../components/Forms/Inputs/MaskedPercentInput.tsx'
import { formatPercent } from '../../utils/formatNumber.ts'
import Input from '../../components/Input.tsx'

interface BonusesFormProps {
  source: SourceByIdQuery['source']
  onDone: () => void
}

interface BonusesUpdateInputs {
  commissionRewardRate: string
  agentContractNumber: string
  agentContractDate: string
}

const BonusesForm: FC<BonusesFormProps> = ({ source, onDone }) => {
  const { control, handleSubmit, setError, setValue, watch, register, formState } = useForm<BonusesUpdateInputs>({
    defaultValues: {
      commissionRewardRate:
        typeof source?.commissionRewardRate === 'number'
          ? formatPercent(source.commissionRewardRate) || '0'
          : undefined,
      agentContractNumber: source?.agentContractNumber,
      agentContractDate:
        source?.agentContractDate && new Date(source.agentContractDate).toISOString().split('T').shift()
    }
  })
  const [updateSource, { loading }] = useUpdateSourceMutation()

  const commissionRewardRate = watch('commissionRewardRate')
  const agentContractNumber = watch('agentContractNumber')

  const onSubmit: SubmitHandler<BonusesUpdateInputs> = async (data) => {
    if (!data) return

    await updateSource({
      variables: {
        input: {
          id: `${source?._id}`,
          commissionRewardRate: parseDecimal(data.commissionRewardRate),
          agentContractNumber: data?.agentContractNumber || null,
          agentContractDate: data.agentContractDate?.length ? new Date(data.agentContractDate).toISOString() : null
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<BonusesUpdateInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Бонусы</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-1 gap-10'>
          <div>
            <Controller
              render={({ field, fieldState }) => (
                <MaskedPercentInput
                  label='Агентское вознаграждение'
                  placeholder='1,5%'
                  inputMode='decimal'
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
              control={control}
              name='commissionRewardRate'
              rules={{ required: true, min: 0 }}
            />
            <CommissionRewardRateList
              commissionRewardRate={commissionRewardRate}
              onChange={(value) => setValue('commissionRewardRate', value)}
              className='mt-3'
            />
          </div>
          <div className='grid grid-cols-2 gap-16'>
            <Input
              label='Агентский договор'
              type='string'
              placeholder='АД220923-01'
              {...register('agentContractNumber')}
              error={formState.errors.agentContractNumber?.message}
            />
            <Input
              label='Дата договора'
              type='date'
              {...register('agentContractDate', { required: !!agentContractNumber })}
              error={formState.errors.agentContractDate?.message}
            />
          </div>
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default BonusesForm
