import { PaymentKind, PaymentState } from '../graphql/schema'

interface Payment {
  state: PaymentState
  date: string
  paidAt?: string
}

export const overdueDays = (payment: Payment, baseDate = new Date()) => {
  const date = new Date(payment.date)
  const dueDate = payment.state === PaymentState.Paid && payment.paidAt ? new Date(payment.paidAt) : baseDate

  return Math.max(0, Math.floor((dueDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)))
}

export const BASE_PAYMENT_KINDS = [
  PaymentKind.Advance,
  PaymentKind.Commission,
  PaymentKind.Compensation,
  PaymentKind.DebtTransferService,
  PaymentKind.Insurance,
  PaymentKind.PenaltyForTermination,
  PaymentKind.Service,
  PaymentKind.Fee,
  PaymentKind.Redemption
]
