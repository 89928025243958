import { FC, useEffect, useMemo, useState } from 'react'
import Input from '../../components/Input'
import { NestedValue, NonUndefined, SubmitHandler, useForm, Controller } from 'react-hook-form'
import {
  SourceCategory,
  SourceByIdQuery,
  useAddSourceMutation,
  useUpdateSourceMutation,
  useCategoriesQuery,
  useAddCompanyMutation,
  UserRoles
} from '../../graphql/schema'
import Select from '../../components/Select'
import clsx from 'clsx'
import { sourceCategoryDict } from '../../utils/dictionaries'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../components/SubmitButton'
import GroupSelectInput, { Option } from '../../components/GroupSelectInput'
import useNodes from '../../hooks/useNodes'
import { NodeType } from '../../types'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'
import { CompanyData } from '../../types/dadata'
import Suggestions from '../../components/Suggestions'
import useThrottledState from '../../hooks/useThrottledState'
import { useCompanySuggestions } from '../../hooks/useSuggestions'
import getCompanyName from '../../utils/getCompanyName'

import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'

type Source = NonUndefined<SourceByIdQuery['source']>
type LeasingSubjectCategory = NodeType<Source['leasingSubjectCategories']>
type Inputs = {
  category: SourceCategory
  userId: number
  bdmUserId: number
  name: string
  company: string
  leasingSubjectCategories: NestedValue<LeasingSubjectCategory[]>
  site: string
}
type SourceCompanyData = {
  inn: string
  name: {
    short_with_opf?: string
    full_with_opf?: string
  }
}
interface SourceFormProps {
  onDone?: () => void
  entity?: Source
}

const listCategories: SourceCategory[] = Object.keys(sourceCategoryDict) as SourceCategory[]

const addHttpsToLink = (link: string) => {
  if (!link.startsWith('http://') && !link.startsWith('https://')) {
    return 'https://' + link
  }
  return link
}

const SourceForm: FC<SourceFormProps> = ({ onDone, entity }) => {
  const [addSource, { loading: addSourceLoading }] = useAddSourceMutation()
  const [updateSource, { loading: updateLoading }] = useUpdateSourceMutation()
  const [addCompany, { loading: addCompanyLoading }] = useAddCompanyMutation()

  const { register, handleSubmit, formState, watch, setValue, getValues, setError, control } = useForm<Inputs>({
    defaultValues: {
      category: entity?.category,
      name: entity?.name,
      userId: entity?.user?._id,
      bdmUserId: entity?.bdmUser?._id,
      company: entity?.company?.shortWithOpf,
      site: entity?.site
    }
  })

  const isLoading = addSourceLoading || updateLoading || addCompanyLoading

  const [company, setCompany] = useState<SourceCompanyData | undefined>(
    entity?.company?.inn
      ? {
          inn: entity.company.inn,
          name: {
            short_with_opf: entity?.company?.shortWithOpf
          }
        }
      : undefined
  )
  const [selectedCategories, setSelectedCategories] = useState<Option[]>([])

  const { data: categoriesData } = useCategoriesQuery()
  const categories = useNodes(categoriesData?.leasingSubjectCategories?.edges)
  const options = useMemo(() => {
    return (
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || []
    )
  }, [categories])

  const category = watch('category')
  const companyQuery = watch('company')

  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)
  const suggestions = useCompanySuggestions(throttledCompanyQuery)

  useEffect(() => {
    if (!companyQuery) {
      setCompany(undefined)
    }
  }, [companyQuery])

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('company', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    let companyId
    if (company?.inn) {
      const { data: addCompanyData } = await addCompany({
        variables: {
          input: {
            inn: company?.inn
          }
        }
      })
      companyId = addCompanyData?.addCompany?.company?._id
    }

    if (!entity) {
      await addSource({
        variables: {
          input: {
            category: data?.category,
            name: `${data?.name}`,
            leasingSubjectCategories: selectedCategories.map((lc) => lc?.value.toString()),
            user: data.userId?.toString() || undefined,
            bdmUser: data.bdmUserId?.toString() || undefined,
            company: companyId?.toString() || undefined,
            site: data?.site ? addHttpsToLink(data.site) : undefined
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      await updateSource({
        variables: {
          input: {
            id: entity._id.toString(),
            category: data?.category,
            name: `${data?.name}`,
            company: companyId?.toString() || undefined,
            site: data?.site ? addHttpsToLink(data.site) : undefined
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    }
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>{!entity ? 'Новый' : 'Редактировать'} источник</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Select
            label='Тип'
            type='text'
            editable={false}
            autoComplete='off'
            value={category ? sourceCategoryDict[category] : ''}
            error={formState.errors.category?.message}
          >
            {listCategories.map((c) => (
              <li
                key={c}
                onClick={() => {
                  setValue('category', c)
                }}
                className={clsx(
                  getValues('category') === c.toString() && 'bg-grayscale-400',
                  'cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                )}
              >
                <div className='mb-1 text-grayscale-0'>{sourceCategoryDict[c]}</div>
              </li>
            ))}
          </Select>
          <Input
            label='Название источника'
            type='text'
            {...register('name', { required: true })}
            error={formState.errors.name?.message}
          />
          <Suggestions<CompanyData>
            suggestions={suggestions?.map((s) => ({
              key: s._id,
              title: getCompanyName(s),
              subtitle: `${s.inn} ${s.address?.value || ''}`,
              payload: s,
              lineThrough: !!s.state.liquidation_date
            }))}
            select={(suggestion) => setCompany(suggestion.payload)}
          >
            <Input
              label='Компания, ИП или ИНН'
              type='text'
              autoComplete='off'
              {...register('company', {
                validate: {
                  // требуем выбора из предложенных вариантов только если апи подсказок работает
                  fromSuggested: (value) => {
                    return value !== getCompanyName(company) ? 'Требуется выбрать один из вариантов' : true
                  }
                }
              })}
              error={formState.errors.company?.message}
            />
          </Suggestions>
          <Input label='Сайт' type='text' {...register('site')} error={formState.errors.site?.message} />
          {!entity && (
            <>
              <Controller
                name='userId'
                control={control}
                render={({ field }) => (
                  <ManagerInput
                    selected={field.value ? [field.value] : []}
                    onChange={(selected) => field.onChange(selected[selected.length - 1])}
                    label='Менеджер по лизингу'
                    error={formState.errors?.userId?.message}
                  />
                )}
              />
              <Controller
                name='bdmUserId'
                control={control}
                render={({ field }) => (
                  <ManagerInput
                    selected={field.value ? [field.value] : []}
                    onChange={(selected) => field.onChange(selected[selected.length - 1])}
                    label='Менеджер по развитию'
                    error={formState.errors?.bdmUserId?.message}
                    role={UserRoles.RoleBdm}
                  />
                )}
              />
              <GroupSelectInput
                label='Категории источника'
                options={options}
                selectedOptions={selectedCategories}
                onChange={setSelectedCategories}
                error={formState.errors.leasingSubjectCategories?.message}
              />
            </>
          )}
        </div>
        <SubmitButton loading={isLoading}>
          <PlusIcon className='mr-5' />
          {!entity ? 'Создать' : 'Изменить'}
        </SubmitButton>
      </form>
    </section>
  )
}

export default SourceForm
