import { useLastAcceptedNotExpiredVerificationQuery } from '../graphql/schema'

export type Contact = {
  _id: number
  verificationStatus: string
}

const useGetVerificationAddress = (inn: string) => {
  const { data: verificationData } = useLastAcceptedNotExpiredVerificationQuery({ variables: { inn: inn } })

  if (!inn.length) {
    return
  }

  return verificationData?.lastAcceptedNotExpiredVerification.personInfo?.regAddress
}

export default useGetVerificationAddress
