import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import { formatMoneyDecimal } from '../../../../utils/formatNumber'

export interface ReconciliationActivatedEventProps extends BaseHistoryEventProps {
  data?: {
    amount?: number
    advanceRate?: number
    application?: number
    interestRate?: number
    comissionRate?: number
    durationMonths?: number
    reconciliationId?: number
    reconciliationNumber?: number
  }
}
const ReconciliationActivatedEvent: FC<ReconciliationActivatedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-labels-tertiary'>взял(а) согласование в работу</span>
        </div>
        <div>{`КП №${data?.reconciliationNumber}, ${data?.amount && formatMoneyDecimal(data.amount)}, А – ${
          data?.advanceRate
        } %, Ста – ${data?.interestRate} %, К – ${data?.comissionRate} %`}</div>
      </div>
    </div>
  )
}

export default ReconciliationActivatedEvent
