import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import UserName from '../../../UserName'

export interface ApplicationAssignedEventProps extends BaseHistoryEventProps {
  data?: {
    previousUserId?: number
    userId?: number
  }
}

const ApplicationAssignedEvent: FC<ApplicationAssignedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-labels-tertiary'>изменил(а) ответственного менеджера</span>
        </div>
        <div className='flex items-start gap-2'>
          {data?.previousUserId && (
            <>
              <UserName userId={data.previousUserId} />
              <div>→</div>
            </>
          )}
          {data?.userId && <UserName userId={data.userId} />}
        </div>
      </div>
    </div>
  )
}

export default ApplicationAssignedEvent
