import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { formatMoneyDecimal } from '../../../../utils/formatNumber'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface ComissionRewardCreatedEventProps extends BaseHistoryEventProps {
  data?: {
    amount?: string
    source?: string
    status?: string
    commissionRate?: string
    agentCommissionRewardId: number
  }
}

const ComissionRewardCreatedEvent: FC<ComissionRewardCreatedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <>
      {data?.amount && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>создал(а) комиссионные в пользу Источника {data.source}</span>
            </div>
            <span>{`${parseFloat(data?.commissionRate || '')}% - ${formatMoneyDecimal(parseInt(data.amount))} ${
              data?.status === 'pending' ? '«Не выплачено»' : '«Выплачено»'
            }`}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default ComissionRewardCreatedEvent
