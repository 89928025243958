import { FC, useMemo } from 'react'
import { BaseHistoryEventProps } from '../..'
import { formatMoneyDecimal } from '../../../../utils/formatNumber'
import {
  DealStatus,
  InsuranceKind,
  PledgeKind,
  RetroBonusKind,
  VatKind,
  useFeraBankAccountsQuery
} from '../../../../graphql/schema'
import UserName from '../../../UserName'
import {
  dealStatusDict,
  insuranceKindDict,
  pledgeKindDict,
  retrobonusKindDict,
  vatKindDict
} from '../../../../utils/dictionaries'
import { dateFormatter } from '../../../../utils/dateFormatter'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import useNodes from '../../../../hooks/useNodes'

export interface DealChangedEventProps extends BaseHistoryEventProps {
  data?: {
    user?: {
      after: number
      before: number
    }
    amount?: {
      after: string
      before: string
    }
    status?: {
      after: DealStatus
      before: DealStatus
    }
    advancePaymentDate?: {
      after: string
      before?: string
    }
    advanceRate?: {
      after: number
      before?: number
    }
    interestRate?: {
      after: number
      before?: number
    }
    insuranceAmount?: {
      after: string
      before?: string
    }
    comissionRate?: {
      after: number
      before?: number
    }
    vatRate?: {
      after: number
      before?: number
    }
    contractDate?: {
      after: string
      before?: string
    }
    durationMonths?: {
      after: number
      before?: number
    }
    insuranceKind?: {
      after: string
      before?: string
    }
    insuranceContract?: {
      after: string
      before?: string
    }
    insuranceContractDate?: {
      after: string
      before?: string
    }
    feraBankAccountId?: {
      after: string
      before?: string
    }
    pledgeKind?: {
      after: string
      before?: string
    }
    kind?: {
      after: string
      before?: string
    }
    retroBonusKind?: {
      after: string
      before?: string
    }
  }
}

const DealChangedEvent: FC<DealChangedEventProps> = ({ actedAt, initiatedByUserId, data, withLine }) => {
  const { data: feraBankAccountsData } = useFeraBankAccountsQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const feraBankAccountIdBefore = data?.feraBankAccountId?.before
  const feraBankAccountIdAfter = data?.feraBankAccountId?.after

  const feraBankAccountNameBefore = useMemo(
    () => feraBankAccounts?.find((account) => account?.id?.toString() === feraBankAccountIdBefore)?.name,
    [feraBankAccounts, feraBankAccountIdBefore]
  )

  const feraBankAccountNameAfter = useMemo(
    () => feraBankAccounts?.find((account) => account?.id?.toString() === feraBankAccountIdAfter)?.name,
    [feraBankAccounts, feraBankAccountIdAfter]
  )

  if (!data) return null

  const { user, status, ...other } = data
  const hasOtherData = Object.keys(other).length > 0

  return (
    <>
      {data?.user && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={hasOtherData ? true : withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>сменил(а) ответственного</span>
            </div>
            <span className='flex gap-2'>
              <UserName userId={data.user.before} /> → <UserName userId={data.user.after} />
            </span>
          </div>
        </div>
      )}
      {data?.status && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>сменил(а) статус сделки</span>
            </div>
            <span className='flex gap-2'>
              {dealStatusDict[data.status.before]} → {dealStatusDict[data.status.after]}
            </span>
          </div>
        </div>
      )}
      {hasOtherData && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />
          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>отредактировал(а) сделку</span>
            </div>
            <span>
              {data?.contractDate?.after && (
                <div>{`Дата договора: ${
                  data?.contractDate?.before ? dateFormatter.format(new Date(data.contractDate.before)) : 'дд.мм.гг'
                } → ${dateFormatter.format(new Date(data.contractDate.after))}`}</div>
              )}
              {data?.advancePaymentDate?.after && (
                <div>
                  {`Дата внесения аванса: ${
                    data.advancePaymentDate.before
                      ? dateFormatter.format(new Date(data.advancePaymentDate.before))
                      : 'дд.мм.гг'
                  } → ${dateFormatter.format(new Date(data.advancePaymentDate.after))}`}
                </div>
              )}
              {data?.amount?.after && (
                <div>{`Сумма: ${formatMoneyDecimal(parseFloat(data?.amount.before))} → ${formatMoneyDecimal(
                  parseFloat(data?.amount.after)
                )}`}</div>
              )}
              {data?.advanceRate?.after && (
                <div>{`Аванс: ${data.advanceRate.before || '-'}% → ${data.advanceRate.after}%`}</div>
              )}
              {data?.interestRate?.after && (
                <div>{`Ставка: ${data.interestRate.before || '-'}% → ${data.interestRate.after}%`}</div>
              )}
              {data?.insuranceAmount?.after && (
                <div>{`Страховка: ${formatMoneyDecimal(
                  parseFloat(data.insuranceAmount.before || '-')
                )} → ${formatMoneyDecimal(parseFloat(data.insuranceAmount.after))}`}</div>
              )}
              {data?.comissionRate?.after && (
                <div>{`Комиссия: ${data.comissionRate.before || '-'}% → ${data.comissionRate.after}%`}</div>
              )}
              {data?.vatRate && (
                <div>{`НДС: ${data.vatRate.before === undefined ? '-' : data.vatRate.before}% → ${
                  data.vatRate.after
                }%`}</div>
              )}
              {data?.durationMonths?.after && (
                <div>{`Срок: ${data.durationMonths.before || '-'} мес. → ${data.durationMonths.after} мес.`}</div>
              )}
              {data?.insuranceKind?.after && (
                <div>{`Страховка: ${insuranceKindDict[data.insuranceKind.before as InsuranceKind] || '-'} → ${
                  insuranceKindDict[data.insuranceKind.after as InsuranceKind]
                }`}</div>
              )}
              {data?.insuranceContract?.after && (
                <div>{`Номер страхового полиса: ${data.insuranceContract.before || '-'} → ${
                  data.insuranceContract.after
                }`}</div>
              )}
              {data?.insuranceContractDate?.after && (
                <div>{`Дата полиса: ${
                  data.insuranceContractDate.before
                    ? dateFormatter.format(new Date(data.insuranceContractDate.before))
                    : 'дд.мм.гг'
                } → ${dateFormatter.format(new Date(data.insuranceContractDate.after))}`}</div>
              )}
              {data?.feraBankAccountId?.after && (
                <div>{`Реквизиты Fera: ${feraBankAccountNameBefore || '-'} → ${feraBankAccountNameAfter}`}</div>
              )}
              {data?.pledgeKind?.after && (
                <div>{`Залог: ${pledgeKindDict[data.pledgeKind.before as PledgeKind] || 'Не указано'} → ${
                  pledgeKindDict[data.pledgeKind.after as PledgeKind]
                }`}</div>
              )}
              {data?.kind?.after && (
                <div>{`Вид сделки (НДС): ${vatKindDict[data.kind.before as VatKind] || '-'} → ${
                  vatKindDict[data.kind.after as VatKind]
                }`}</div>
              )}
              {data?.retroBonusKind?.after && (
                <div>{`Ретробонус: ${retrobonusKindDict[data.retroBonusKind.before as RetroBonusKind] || '-'} → ${
                  retrobonusKindDict[data.retroBonusKind.after as RetroBonusKind]
                }`}</div>
              )}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default DealChangedEvent
