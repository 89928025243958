import { FC } from 'react'
import { DocumentTypeEntity, useCompanyByCustomerCompanyIdQuery } from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'
import { useParams } from 'react-router-dom'

const Documents: FC = () => {
  const { id } = useParams<'id'>()

  const { data } = useCompanyByCustomerCompanyIdQuery({ variables: { id: id || '' }, skip: !id })
  if (!id) return null

  return (
    <div className='flex flex-col gap-10'>
      <DocumentManager entityId={parseInt(id)} entityType={DocumentTypeEntity.CustomerCompany} />
      {data?.customerCompany?.company?._id && (
        <DocumentManager entityId={data?.customerCompany?.company?._id} entityType={DocumentTypeEntity.Company} />
      )}
    </div>
  )
}

export default Documents
