import { useState } from 'react'
import { useLastAcceptedVerificationLazyQuery } from '../graphql/schema.tsx'

const usePersonAddressLazy = () => {
  const [error, setError] = useState(false)
  const [getVerification, { loading }] = useLastAcceptedVerificationLazyQuery()

  const loadAddress = async (inn?: string) => {
    if (!inn || loading) return

    try {
      const { data } = await getVerification({
        variables: {
          inn
        }
      })
      if (!data?.lastAcceptedVerification) {
        setError(true)
        return undefined
      }

      return data.lastAcceptedVerification?.personInfo?.regAddress
    } catch (e) {
      console.error(e)
    }
  }
  return [loadAddress, loading, error] as const
}

export default usePersonAddressLazy
