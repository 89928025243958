import { FC, useEffect } from 'react'
import { PaymentKind, PaymentTargetType, useCreatePaymentMutation } from '../../../graphql/schema.tsx'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import Input from '../../../components/Input.tsx'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import { ApolloError } from '@apollo/client'
import { parseDecimalFromMasked } from '../../../utils/parseDecimal.ts'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import Select from '../../../components/Select.tsx'
import SubmitButton from '../../../components/SubmitButton'
import c from 'clsx'
import { getCalendarFormatDate } from '../../../utils/dateFormatter.ts'
import MaskedNumberInput from '../../../components/Forms/Inputs/MaskedNumberInput.tsx'
import { formatDecimal } from '../../../utils/formatNumber.ts'

type Inputs = {
  date: string
  amount: string
  kind: PaymentKind
  targetId: string
}

interface PaymentFormProps {
  dealId: number
  onDone?: () => void
  kinds?: PaymentKind[]
  advanceAmount?: number
  commissionAmount?: number
  insuranceAmount?: number
}

const PaymentForm: FC<PaymentFormProps> = ({
  dealId,
  onDone,
  kinds,
  advanceAmount,
  commissionAmount,
  insuranceAmount
}) => {
  const [createPayment, { loading }] = useCreatePaymentMutation()

  const { register, handleSubmit, setError, watch, setValue, getValues, control } = useForm<Inputs>({
    defaultValues: {
      date: getCalendarFormatDate(new Date())
    }
  })

  const paymentKind = watch('kind')

  useEffect(() => {
    if (paymentKind === PaymentKind.Advance) {
      if (!advanceAmount) return
      setValue('amount', formatDecimal(advanceAmount * 100))
    }
    if (paymentKind === PaymentKind.Insurance) {
      console.log(insuranceAmount)
      if (!insuranceAmount) return
      setValue('amount', formatDecimal(insuranceAmount))
    }
    if (paymentKind === PaymentKind.Commission) {
      if (!commissionAmount) return
      setValue('amount', formatDecimal(commissionAmount * 100))
    }
  }, [paymentKind, setValue, advanceAmount, insuranceAmount, commissionAmount])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading) return

    await createPayment({
      variables: {
        input: {
          date: data.date,
          amount: parseDecimalFromMasked(data.amount),
          kind: data.kind || PaymentKind.Leasing,
          targetType: PaymentTargetType.Deal,
          targetId: dealId.toString()
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Новый платеж</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          {!!kinds && (
            <Select
              label='Назначение платежа'
              placeholder='Выберите назначение платежа'
              type='text'
              autoComplete='off'
              {...register('kind', { required: true })}
              value={paymentKindDict[watch('kind')] || ''}
            >
              {kinds.map((kind) => (
                <li
                  key={kind}
                  onClick={() => {
                    setValue('kind', kind)
                    setValue('amount', kind === PaymentKind.DebtTransferService ? '20 000' : '')
                  }}
                  className={c(
                    'cursor-pointer px-12 py-5 hover:bg-grayscale-450',
                    getValues('kind') === kind && 'bg-grayscale-400'
                  )}
                >
                  <div className='mb-1 text-base-black'>{paymentKindDict[kind]}</div>
                </li>
              ))}
            </Select>
          )}
          <Input label='Дата платежа' type='date' {...register('date', { required: true })} />

          <Controller
            render={({ field, fieldState }) => {
              return (
                <MaskedNumberInput
                  label='Сумма'
                  placeholder='15 000,00'
                  inputMode='decimal'
                  error={fieldState.error?.message}
                  {...field}
                />
              )
            }}
            name='amount'
            control={control}
            rules={{ required: true, min: 0 }}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать
        </SubmitButton>
      </form>
    </section>
  )
}

export default PaymentForm
