import { useSwitcherState, StateDefinition } from '../../hooks/useSwitcherState'
import {
  AccessAbilitiesDocument,
  AccessAbilitiesQuery,
  AccessAbilitiesQueryVariables,
  ApplicationReconciliationsStatus,
  ApplicationStatusSelectDocument,
  ApplicationStatusSelectQuery,
  ApplicationStatusSelectQueryVariables,
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  ReconciliationCustomerStatus,
  ReconciliationFeraStatus
} from '../../graphql/schema'
import { ApolloClient, QueryResult, useApolloClient } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { BehaviorSubject, combineLatest, from, map, Observable } from 'rxjs'
import { AppStatus } from '../../components/ApplicationStatus'
import { createObservableChecker, createQueryChecker } from '../../utils/checkQuery.ts'
import { captureMessage } from '@sentry/react'

interface ApplicationSwitcherStateContext {
  applicationId: string
  currentUserId: number | undefined
  client: ApolloClient<unknown>
}

const watchApplication = ({ client, applicationId }: ApplicationSwitcherStateContext) => {
  return client.watchQuery<ApplicationStatusSelectQuery, ApplicationStatusSelectQueryVariables>({
    query: ApplicationStatusSelectDocument,
    variables: { id: applicationId }
  })
}

const watchAccessAbilities = (client: ApolloClient<unknown>) => {
  return client.watchQuery<AccessAbilitiesQuery, AccessAbilitiesQueryVariables>({
    query: AccessAbilitiesDocument
  })
}

const watchCurrentUser = (client: ApolloClient<unknown>, userId: number | undefined) => {
  return client.watchQuery<CurrentUserQuery, CurrentUserQueryVariables>({
    query: CurrentUserDocument,
    variables: { id: userId?.toString() || '' }
  })
}

const watchAccessAbilitiesAndCurrentUser = (context: ApplicationSwitcherStateContext) => {
  const accessAbilities$ = from(watchAccessAbilities(context.client)) as Observable<QueryResult<AccessAbilitiesQuery>>
  const currentUser$ = from(watchCurrentUser(context.client, context.currentUserId)) as Observable<
    QueryResult<CurrentUserQuery>
  >

  const result = combineLatest([accessAbilities$, currentUser$]).pipe(
    map(([accessAbilitiesResult, currentUserResult]) => {
      return {
        data: {
          accessAbilities: accessAbilitiesResult?.data?.accessAbilities,
          currentUser: currentUserResult?.data?.user
        },
        loading: accessAbilitiesResult.loading || currentUserResult.loading,
        error: accessAbilitiesResult.error || currentUserResult.error
      }
    })
  )

  return result
}

const recStatusNegotiations = createQueryChecker(watchApplication, (data) => {
  if (data?.application?.reconciliationsStatus === ApplicationReconciliationsStatus.Negotiations)
    return { passed: true }
  return { passed: false, message: 'Добавить согласования в работу' }
})

const hasAcceptedRecs = createQueryChecker(watchApplication, (data) => {
  const reconciliations = data?.application?.reconciliations?.edges?.map((e) => e?.node) || []
  const acceptedRecs = reconciliations.filter(
    (rec) =>
      rec?.feraStatus === ReconciliationFeraStatus.Accepted &&
      rec?.customerStatus === ReconciliationCustomerStatus.Accepted &&
      rec?.feraAcceptExpiresAt &&
      new Date() <= new Date(rec?.feraAcceptExpiresAt)
  )
  if (acceptedRecs.length > 0) return { passed: true }
  return { passed: false, message: 'Согласовать условия' }
})

const edoSpecified = createQueryChecker(watchApplication, (data) => {
  const customer = data.application?.customerCompany
  if (
    customer?.edoStatus === false ||
    (customer?.edoStatus === true && (!!customer?.edoTypes?.totalCount || !!customer?.edoCustomValue))
  )
    return { passed: true }
  return { passed: false, message: 'Указать ЭДО' }
})

const canChangeStatusFromClosed = createObservableChecker(
  watchAccessAbilitiesAndCurrentUser,
  ({ accessAbilities, currentUser }, _context, currentState) => {
    if (currentState !== AppStatus.Closed) return { passed: true }

    const targetAbility = accessAbilities?.edges?.find(
      (accessAbility) => accessAbility?.node?.ability === 'application.status_closed.manage'
    )?.node

    let hasAccess = false
    if (!targetAbility) {
      console.log(`Правило доступа "application.status_closed.manage" не найдено!`)
      captureMessage(`Правило доступа "application.status_closed.manage" не найдено!`)
    } else {
      currentUser?.roles.forEach((role) => {
        if (targetAbility?.roles?.includes(role)) {
          hasAccess = true
        }
      })
    }

    return { passed: hasAccess }
  }
)

const applicationStateDefinitions: StateDefinition<AppStatus, ApplicationSwitcherStateContext>[] = [
  {
    state: AppStatus.FirstContact,
    nextStates: [
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ],
    checks: [canChangeStatusFromClosed]
  },
  {
    state: AppStatus.Reconciliation,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ],
    checks: [canChangeStatusFromClosed]
  },
  {
    state: AppStatus.DecisionMaking,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ],
    checks: [canChangeStatusFromClosed]
  },
  {
    state: AppStatus.DataCollection,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.Scoring,
      AppStatus.Closed
    ],
    checks: [canChangeStatusFromClosed]
  },
  {
    state: AppStatus.Scoring,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.DealPreparation,
      AppStatus.Deal,
      AppStatus.Closed
    ],
    checks: [canChangeStatusFromClosed, recStatusNegotiations]
  },
  {
    state: AppStatus.DealPreparation,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Deal,
      AppStatus.Closed
    ],
    checks: [canChangeStatusFromClosed]
  },
  {
    state: AppStatus.Deal,
    nextStates: [],
    checks: [canChangeStatusFromClosed, hasAcceptedRecs, edoSpecified]
  },
  {
    state: AppStatus.Closed,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ]
  }
]

export function useApplicationSwitcherState(
  applicationId: string,
  applicationStatus: AppStatus | undefined,
  currentUserId: number | undefined
) {
  const client = useApolloClient()

  const context$ = useRef(
    new BehaviorSubject<ApplicationSwitcherStateContext>({
      client,
      applicationId,
      currentUserId
    })
  ).current

  useEffect(() => {
    context$.next({
      client,
      applicationId,
      currentUserId
    })
  }, [client, applicationId, currentUserId, context$])

  return useSwitcherState(applicationStatus, applicationStateDefinitions, context$)
}
