import {
  useUploadDocumentMutation,
  DocumentTypeEntity,
  DocumentsDocument,
  DocumentsQuery,
  DocumentsQueryVariables
} from '../../graphql/schema'
import { useCallback } from 'react'
import useDocumentTypes from './useDocumentTypes'

let id = -1
const getTempId = () => {
  id--
  return id
}

interface UploadDocumentOptions {
  entityId?: string
  entityType: DocumentTypeEntity
  folderName: string
}

const useUploadDocuments = (options: UploadDocumentOptions) => {
  const { entityType, folderName } = options
  const [uploadDocument] = useUploadDocumentMutation()

  const docTypes = useDocumentTypes(entityType)
  const typeId = docTypes?.find((t) => t?.type === folderName)?._id

  const uploadDocuments = useCallback(
    async (files: File[], override?: { entityId: string }) => {
      if (!files.length) return
      if (!typeId) {
        throw new Error('Document type not found')
      }
      const entityId = override?.entityId || options.entityId
      if (!entityId) {
        throw new Error('Entity id not found')
      }

      await Promise.all(
        Array.from(files).map((file) =>
          uploadDocument({
            variables: {
              input: {
                file: file,
                type: typeId.toString(),
                application: entityType === DocumentTypeEntity.Application ? entityId?.toString() : undefined,
                customerCompany: entityType === DocumentTypeEntity.CustomerCompany ? entityId?.toString() : undefined,
                deal: entityType === DocumentTypeEntity.Deal ? entityId?.toString() : undefined,
                dealSupply: entityType === DocumentTypeEntity.DealSupply ? entityId?.toString() : undefined,
                shipment: entityType === DocumentTypeEntity.Shipment ? entityId?.toString() : undefined,
                contact: entityType === DocumentTypeEntity.Contact ? entityId?.toString() : undefined,
                supplierCompany: entityType === DocumentTypeEntity.SupplierCompany ? entityId?.toString() : undefined,
                source: entityType === DocumentTypeEntity.Source ? entityId?.toString() : undefined,
                guarantor: entityType === DocumentTypeEntity.Guarantor ? entityId?.toString() : undefined,
                agentCommissionReward:
                  entityType === DocumentTypeEntity.AgentCommissionReward ? entityId?.toString() : undefined,
                dealRetrobonus: entityType === DocumentTypeEntity.DealRetrobonus ? entityId?.toString() : undefined,
                fundingSource: entityType === DocumentTypeEntity.FundingSource ? entityId?.toString() : undefined,
                applicationSupplierCompany:
                  entityType === DocumentTypeEntity.ApplicationSupplierCompany ? entityId?.toString() : undefined,
                company: entityType === DocumentTypeEntity.Company ? entityId?.toString() : undefined
              }
            },
            // optimisticResponse for file upload with loading state
            optimisticResponse: {
              __typename: 'Mutation',
              uploadDocument: {
                __typename: 'uploadDocumentPayload',
                document: {
                  __typename: 'Document',
                  _id: getTempId(),
                  created: new Date().toISOString(),
                  originalName: file.name,
                  mimeType: file.type,
                  filePath: '',
                  loading: true,
                  type: {
                    __typename: 'DocumentType',
                    _id: typeId,
                    entity: entityType
                  },
                  user: {
                    __typename: 'User',
                    _id: -1,
                    name: '',
                    surname: ''
                  }
                }
              }
            },
            update: (cache, { data }) => {
              const document = data?.uploadDocument?.document
              if (!document) return

              const cachedDocuments = cache.readQuery<DocumentsQuery, DocumentsQueryVariables>({
                query: DocumentsDocument,
                variables: {
                  filter: {
                    entityId: entityId.toString(),
                    entityType: entityType,
                    type: folderName
                  }
                }
              })

              if (!cachedDocuments?.documents) return

              const docs = cachedDocuments?.documents?.edges || []

              cache.writeQuery<DocumentsQuery, DocumentsQueryVariables>({
                query: DocumentsDocument,
                variables: {
                  filter: {
                    entityId: entityId.toString(),
                    entityType,
                    type: folderName
                  }
                },
                data: {
                  __typename: 'Query',
                  documents: {
                    __typename: 'DocumentConnection',
                    edges: [
                      ...docs,
                      {
                        __typename: 'DocumentEdge',
                        node: document
                      }
                    ]
                  }
                }
              })
            }
          })
        )
      )
    },
    [typeId, uploadDocument, entityType, folderName, options.entityId]
  )

  return uploadDocuments
}

export default useUploadDocuments
