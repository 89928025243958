import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface GuarantorCreatedEventProps extends BaseHistoryEventProps {
  data?: {
    guarantorId?: number
    guarantorName?: string
  }
}

const GuarantorCreatedEvent: FC<GuarantorCreatedEventProps> = ({ actedAt, initiatedByUserId, data, withLine }) => {
  return (
    <>
      {data?.guarantorName && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>добавил(а) поручителя</span>
            </div>
            {data.guarantorName}
          </div>
        </div>
      )}
    </>
  )
}

export default GuarantorCreatedEvent
