import {
  PaymentKind,
  PaymentTargetType,
  useCreatePaymentMutation,
  useDealPenaltyAmountSuspenseQuery,
  usePaymentByIdSuspenseQuery
} from '../../../graphql/schema.tsx'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import Input from '../../../components/Input.tsx'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import { ApolloError } from '@apollo/client'
import parseDecimal from '../../../utils/parseDecimal.ts'
import SubmitButton from '../../../components/SubmitButton'
import { FC } from 'react'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import { getCalendarFormatDate } from '../../../utils/dateFormatter.ts'
import MaskedNumberInput from '../../../components/Forms/Inputs/MaskedNumberInput.tsx'

type Inputs = {
  date: string
  amount: string
  kind: PaymentKind
  targetId: string
}

interface PaymentFormProps {
  paymentId: string
  onDone?: () => void
}

const PenaltyForm: FC<PaymentFormProps> = ({ paymentId, onDone }) => {
  const [createPayment, { loading }] = useCreatePaymentMutation()
  const { data } = usePaymentByIdSuspenseQuery({
    variables: {
      id: paymentId
    }
  })

  const payment = data?.payment

  const { data: penaltyData } = useDealPenaltyAmountSuspenseQuery({ variables: { paymentId: paymentId } })
  const penaltyAmount = penaltyData?.penaltyAmountForPayment.amount

  const { register, handleSubmit, setError, control } = useForm<Inputs>({
    defaultValues: {
      amount: formatDecimal((penaltyAmount || 0) * 100),
      date: payment?.date ? getCalendarFormatDate(new Date(payment.date)) : getCalendarFormatDate(new Date())
    }
  })

  // тут до этого был какой-то треш с useEffect, понятия не имею что делал тот код но насколько я понял только создавал баги. [IT-4269]

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading) return

    await createPayment({
      variables: {
        input: {
          date: data.date,
          amount: parseDecimal(data.amount.replace(/\s/g, '')),
          kind: PaymentKind.Penalty,
          targetType: PaymentTargetType.Payment,
          targetId: payment?.id as string
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Новый платеж</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Input label='Дата платежа' type='date' {...register('date', { required: true })} />
          <Controller
            render={({ field, fieldState }) => {
              return (
                <MaskedNumberInput
                  label='Сумма'
                  placeholder='15 000,00'
                  inputMode='decimal'
                  error={fieldState.error?.message}
                  {...field}
                />
              )
            }}
            name='amount'
            control={control}
            rules={{ required: true, min: 0 }}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать
        </SubmitButton>
      </form>
    </section>
  )
}

export default PenaltyForm
