import { formatDecimal } from '../../../utils/formatNumber'
import Modal from '../../../components/Modal'
import ReconciliationForm from './ReconciliationForm'
import SubmitButton from '../../../components/SubmitButton'
import { Disclosure } from '@headlessui/react'
import c from 'clsx'
import { FC, useCallback, useState, useMemo } from 'react'
import GridView, { GridViewConfig } from '../../../components/GridView'
import {
  ApplicationReconciliationsStatus,
  ApplicationReconciliationsQuery,
  useApplicationReconciliationsQuery,
  useUpdateReconciliationActualMutation,
  useUpdateReconciliationStatusMutation
} from '../../../graphql/schema'
import { useParams } from 'react-router-dom'
import { NodeType } from '../../../types'
import useNodes from '../../../hooks/useNodes'
import ReconciliationDownloadCommercial from '../ReconciliationDownloadCommercial'
import getReconciliationFileName from '../../../utils/getReconciliationFileName'
import { dateFormatter } from '../../../utils/dateFormatter'
import NegotiationStep from './NegotiationStep'
import { ReactComponent as CircleCheckIcon } from '../../../svg/icons/circleCheck.svg'
import { ReactComponent as CircleEmptyIcon } from '../../../svg/icons/circleEmpty.svg'
import { ReactComponent as CircleCrossIcon } from '../../../svg/icons/circleCross.svg'
import { ReactComponent as CircleSlotIcon } from '../../../svg/icons/circleSlot.svg'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import { ReactComponent as ChevronIcon } from '../../../svg/icons/chevron-slim.svg'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick.svg'
import Dropdown from '../../../components/Dropdown'
import getReconciliationStatus from '../../../utils/reconciliationStatus'
import { ReconciliationProps } from '../../../utils/reconciliationStatus'
import getVatKind from '../../../utils/getVatKind.ts'

export type Reconciliation = NodeType<NonNullable<ApplicationReconciliationsQuery['application']>['reconciliations']>

const Reconciliations: FC = () => {
  const { id } = useParams<'id'>()
  const [openTab, setOpenTab] = useState<number | undefined>(0)
  const { data, loading, error, refetch } = useApplicationReconciliationsQuery({
    variables: { id: id as string },
    skip: !id
  })
  const app = data?.application
  const recs = useNodes(app?.reconciliations?.edges)
  const negotiations = app?.reconciliationsStatus === ApplicationReconciliationsStatus.Negotiations

  const [formOpen, setFormOpen] = useState(false)

  const [updateStatus, { loading: statusUpdateLoading }] = useUpdateReconciliationStatusMutation()
  const [updateReconciliationActual] = useUpdateReconciliationActualMutation()

  const hasCategories = !!app?.leasingSubjectCategories?.totalCount

  const hasManager = !!app?.user?._id

  const [actual, archive] = useMemo(
    () =>
      recs.reduce<[Reconciliation[], Reconciliation[]]>(
        (acc, rec) => {
          if (rec.isActual) {
            acc[0].push(rec)
          } else {
            acc[1].push(rec)
          }
          return acc
        },
        [[], []]
      ),
    [recs]
  )

  const RecButton = (
    <button
      onClick={() => setFormOpen(true)}
      disabled={!hasCategories || !hasManager}
      className='mb-4 flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
    >
      {hasCategories && hasManager ? (
        <>
          <PlusIcon />
          Новое согласование
        </>
      ) : (
        <>
          <WarningIcon />
          <span>
            Необходимо&nbsp;
            {!hasCategories && <span>указать категорию&nbsp;</span>}
            {!hasCategories && !hasManager && <span>и&nbsp;</span>}
            {!hasManager && <span>указать ответственного менеджера</span>}
          </span>
        </>
      )}
    </button>
  )

  const updateActual = useCallback(
    async (recId: number, actual: boolean) => {
      await updateReconciliationActual({
        variables: {
          input: {
            id: recId.toString(),
            isActual: actual
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateReconciliation: {
            __typename: 'updateReconciliationPayload',
            reconciliation: {
              __typename: 'Reconciliation',
              _id: recId,
              isActual: actual
            }
          }
        }
      })
    },
    [updateReconciliationActual]
  )

  const reconciliationsConfig: GridViewConfig<Reconciliation> = {
    grid: 'grid-cols-reconciliation',
    columns: [
      { title: '№', value: 'reconciliationNumber', numeric: true },
      { title: 'Создано', value: (r) => dateFormatter.format(new Date(r.created)) },
      { title: 'Стоимость оборудования, ₽', value: (r) => formatDecimal(r.amount) },
      { title: 'Платёж, ₽', value: (r) => formatDecimal(r.monthPayment) },
      { title: 'Срок', value: 'durationMonths' },
      { title: 'Аванс', value: (r) => r.advanceRate + ' %' },
      { title: 'Ставка', value: (r) => r.interestRate + ' %' },
      { title: 'Страховка', value: (r) => r.insuranceRate + ' %' },
      { title: 'Комиссия', value: (r) => r.comissionRate + ' %' },
      {
        title: 'Вид (НДС)',
        value: (r) => getVatKind(r.kind)
      },
      {
        title: 'PDF',
        value: (r) => (
          <div className='mx-auto'>
            <ReconciliationDownloadCommercial
              recId={r._id}
              fileName={getReconciliationFileName(r.reconciliationNumber, r._id)}
            />
          </div>
        )
      },
      {
        value: (r) =>
          negotiations ? (
            <Dropdown className='ml-auto pl-4 pr-4'>
              <button
                className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                onClick={() => updateActual(r._id, true)}
              >
                <TickIcon className='mr-7 flex-none' width='18px' height='18px' title='Взять в работу' />
                <span className='whitespace-nowrap text-grayscale-0'>Взять в работу</span>
              </button>
            </Dropdown>
          ) : (
            <div className='flex items-center justify-end gap-7'>
              {r.isActual ? (
                <CircleCheckIcon className='cursor-pointer text-red-100' onClick={() => updateActual(r._id, false)} />
              ) : (
                <CircleEmptyIcon className='cursor-pointer' onClick={() => updateActual(r._id, true)} />
              )}
            </div>
          )
      }
    ]
  }

  const reconciliationIcon = (data: ReconciliationProps) => {
    const [reconciliationStatus] = getReconciliationStatus(data)
    if (reconciliationStatus === 'accepted') {
      return <CircleCheckIcon className='text-green-600' />
    } else if (reconciliationStatus === 'rejected') {
      return <CircleCrossIcon className='text-red-50' />
    } else if (reconciliationStatus === 'otherRejected') {
      return <CircleSlotIcon className='text-grayscale-300' />
    } else return <CircleEmptyIcon />
  }

  return (
    <>
      {negotiations && (
        <>
          {actual.map((rec, i, arr) => {
            const open = openTab === i

            const prev = arr[i + 1]
            const feraAcceptExpired = Boolean(
              rec?.feraAcceptExpiresAt && new Date() >= new Date(rec?.feraAcceptExpiresAt)
            )
            const feraReconciliationInfo = {
              customerStatus: rec.customerStatus,
              feraStatus: rec.feraStatus,
              feraAcceptExpired: feraAcceptExpired,
              ourSide: true
            }

            const clientReconciliationInfo = {
              customerStatus: rec.customerStatus,
              feraStatus: rec.feraStatus,
              ourSide: false
            }
            return (
              <div
                key={rec._id}
                data-headlessui-state={open && 'open'}
                className='mb-8 cursor-pointer rounded-xl bg-surface-secondary p-5'
              >
                <div>
                  <button
                    onClick={() => setOpenTab((tab) => (tab === i ? undefined : i))}
                    className='flex w-full items-center px-6 py-6'
                  >
                    <div className='flex items-center font-display text-h200 tabular-nums'>
                      Согласование №{rec.reconciliationNumber}
                      <ChevronIcon className='ml-10 transition-transform ui-open:-rotate-180' />
                    </div>
                    <div className='ml-10 flex ui-open:hidden'>{reconciliationIcon(feraReconciliationInfo)}</div>
                    <div className='ml-10 flex ui-open:hidden'>{reconciliationIcon(clientReconciliationInfo)}</div>
                    <div className='ml-5 text-p100 font-normal text-grayscale-250 ui-open:hidden'>
                      {
                        <span
                          className={c(
                            'ml-5',
                            !!prev && prev.amount !== rec.amount && 'font-medium text-grayscale-150'
                          )}
                        >
                          {formatDecimal(rec.amount)} ₽
                        </span>
                      }
                      {
                        <span
                          className={c(
                            'ml-5',
                            !!prev && prev.durationMonths !== rec.durationMonths && 'font-medium text-grayscale-150'
                          )}
                        >
                          {rec.durationMonths} мес.
                        </span>
                      }
                      {
                        <span
                          className={c(
                            'ml-5',
                            !!prev && prev.advanceRate !== rec.advanceRate && 'font-medium text-grayscale-150'
                          )}
                        >
                          А – {rec.advanceRate} %
                        </span>
                      }
                      {
                        <span
                          className={c(
                            'ml-5',
                            !!prev && prev.interestRate !== rec.interestRate && 'font-medium text-grayscale-150'
                          )}
                        >
                          Ста – {rec.interestRate} %
                        </span>
                      }
                      {
                        <span
                          className={c(
                            'ml-5',
                            !!prev && prev.insuranceRate !== rec.insuranceRate && 'font-medium text-grayscale-150'
                          )}
                        >
                          Стр – {rec.insuranceRate} %
                        </span>
                      }
                      {
                        <span
                          className={c(
                            'ml-5',
                            !!prev && prev.comissionRate !== rec.comissionRate && 'font-medium text-grayscale-150'
                          )}
                        >
                          К – {rec.comissionRate} %
                        </span>
                      }
                    </div>
                  </button>
                  {open && (
                    <div className=''>
                      <NegotiationStep
                        appId={app?._id}
                        rec={rec}
                        commissionRewardRate={app?.source?.commissionRewardRate}
                        diffRec={prev}
                        onRecAdd={() => {
                          refetch()
                          setOpenTab(0)
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
          <div className='mb-8 cursor-pointer rounded-xl bg-surface-secondary p-5'>
            <Disclosure>
              <Disclosure.Button className='flex w-full items-center px-6 py-6'>
                <div className='flex items-center font-display text-h200'>
                  Черновики <ChevronIcon className='ml-10 transition-transform ui-open:-rotate-180' />
                </div>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className='py-4'>{RecButton}</div>
                <GridView data={archive} config={reconciliationsConfig} loading={loading} error={error?.message} />
              </Disclosure.Panel>
            </Disclosure>
          </div>
        </>
      )}
      {!negotiations && (
        <>
          <div className='rounded-xl bg-surface-secondary p-5'>
            {RecButton}

            {!!recs.length && (
              <GridView data={recs} config={reconciliationsConfig} loading={loading} error={error?.message} />
            )}
          </div>
          <div className='flex'>
            <div className='ml-auto'>
              <SubmitButton
                className='ml-auto mt-8 block w-auto shadow-xs disabled:bg-white-0'
                onClick={() =>
                  updateStatus({
                    variables: {
                      input: {
                        id: app?._id.toString() as string,
                        reconciliationsStatus: ApplicationReconciliationsStatus.Negotiations
                      }
                    }
                  })
                }
                loading={statusUpdateLoading}
                disabled={!actual.length}
              >
                {actual.length ? 'Запустить в работу' : 'Не выбрано согласование'}
              </SubmitButton>
            </div>
          </div>
        </>
      )}
      {!loading && !!app && (
        <Modal open={formOpen} setOpen={setFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <ReconciliationForm
              defaultValues={
                recs.length
                  ? {
                      ...recs[0],
                      // don't inherit requiredGuarantorsCount property because managers can't edit it
                      // and it breaks process when trying to create a draft after negotiations started
                      requiredGuarantorsCount: 0
                    }
                  : {
                      amount: app.amount,
                      durationMonths: app.durationMonths,
                      advanceRate: app.advanceRate
                    }
              }
              commissionRewardRate={app.source.commissionRewardRate}
              applicationId={app?._id.toString()}
              companyRegDate={app?.customerCompany?.registrationDate}
              onDone={() => {
                refetch()
                setFormOpen(false)
              }}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default Reconciliations
