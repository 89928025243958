import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import { formatMoneyDecimal } from '../../../../utils/formatNumber'

export interface ReconciliationCustomerStatusUpdatedEventProps extends BaseHistoryEventProps {
  data?: {
    diff?: {
      customerStatus?: {
        before: string
        after: string
      }
    }
    amount?: number
    advanceRate?: number
    application?: number
    interestRate?: number
    comissionRate?: number
    durationMonths?: number
    reconciliationId?: number
    reconciliationNumber?: number
  }
}

const reconciliationStatusDict: Record<string, string> = {
  pending: 'В работе',
  accepted: 'Принято',
  rejected: 'Отказано'
}

const ReconciliationCustomerStatusUpdatedEvent: FC<ReconciliationCustomerStatusUpdatedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-labels-tertiary'>изменил(а) статус согласования «Клиент»</span>
        </div>
        <div className='flex items-start gap-2'>
          {data?.diff?.customerStatus &&
            `${reconciliationStatusDict[data.diff.customerStatus.before]} →
              ${reconciliationStatusDict[data.diff.customerStatus.after]} в согласовании КП №${data.reconciliationNumber}, ${data.amount && formatMoneyDecimal(data.amount)}, А – ${
                data.advanceRate
              } %, Ста – ${data.interestRate} %, К – ${data.comissionRate} %`}
        </div>
      </div>
    </div>
  )
}

export default ReconciliationCustomerStatusUpdatedEvent
