import { FC, useState } from 'react'
import { ReactComponent as ZipIcon } from '../../../svg/icons/zip.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { emptyCell, moneyCell, renderSheet, saveSheet, textCell } from '../../../utils/XLSX.ts'
import {
  PaymentKind,
  PaymentTargetType,
  useDealForPaymentScheduleXlsxQuery,
  usePaymentsLazyQuery
} from '../../../graphql/schema.tsx'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import c from 'clsx'

interface PaymentScheduleSaveBtnProps {
  dealId: string
  className?: string
}

const PaymentScheduleSaveBtn: FC<PaymentScheduleSaveBtnProps> = ({ dealId, className }) => {
  const [loading, setLoading] = useState(false)

  const [getPayments] = usePaymentsLazyQuery()

  const { data } = useDealForPaymentScheduleXlsxQuery({
    variables: { id: dealId },
    skip: !dealId,
    fetchPolicy: 'cache-and-network'
  })
  const deal = data?.deal

  const handleSaveSchedule = async () => {
    setLoading(true)
    try {
      const { data: paymentsData } = await getPayments({
        variables: {
          kinds: [PaymentKind.Advance, PaymentKind.Leasing, PaymentKind.Redemption],
          targetType: PaymentTargetType.Deal,
          targetId: dealId
        }
      })
      const paymentsRaw =
        paymentsData?.payments?.edges
          ?.map((e) => e?.node)
          .sort((a, b) => {
            if (a?.kind === PaymentKind.Redemption && b?.kind !== PaymentKind.Redemption) {
              return 1
            } else if (b?.kind === PaymentKind.Redemption && a?.kind !== PaymentKind.Redemption) {
              return -1
            } else {
              return 0 // иначе сохраняем порядок
            }
          }) || []
      const leasingPayments = paymentsRaw?.filter((p) => p?.kind === PaymentKind.Leasing) || []

      if (deal?.vatRate === undefined) throw new Error('vatRate not found!')

      const payments = paymentsRaw?.map((p) => ({
        amount: parseFloat((p?.amount || 0).toFixed(2)),
        fee: parseFloat((p?.fee || 0).toFixed(2)),
        redemption: parseFloat((p?.redemption || 0).toFixed(2)),
        body:
          p?.kind === PaymentKind.Advance
            ? parseFloat((p?.amount || 0).toFixed(2))
            : parseFloat((p?.body || 0).toFixed(2)),
        vat: parseFloat((((p?.amount || 0) / (100 + deal.vatRate)) * deal.vatRate).toFixed(2)),
        number:
          p?.kind === PaymentKind.Leasing
            ? `${leasingPayments?.findIndex((payment) => payment?.id === p.id) + 1}`
            : p?.kind && paymentKindDict[p.kind],
        date: p?.date ? dateFormatter.format(new Date(p.date)) : ''
      }))

      const total = payments?.reduce(
        (acc, item) => {
          acc.amount += item?.amount
          acc.fee += item?.fee
          acc.body += item?.body
          acc.vat += item.vat
          return acc
        },
        {
          amount: 0,
          fee: 0,
          redemption: 0,
          body: 0,
          vat: 0,
          number: '',
          date: 'Итого'
        }
      )
      payments.push(total)

      const ws = renderSheet({
        data: payments,
        columns: [
          {
            title: '№',
            width: 10,
            render: (p) => (p?.number ? textCell(p.number) : emptyCell)
          },
          {
            title: 'Дата лизингового платежа',
            width: 10,
            render: (p) => textCell(p.date)
          },
          {
            title: 'Сумма лизингового платежа с НДС, руб.',
            width: 15,
            render: (p) => moneyCell(p.amount)
          },
          {
            title: 'Сумма возврата финансирования в составе лизингового платежа с НДС, руб.',
            width: 15,
            render: (p) => moneyCell(p.body)
          },
          {
            title: 'Плата за финансирование в составе лизингового платежа с НДС, руб.',
            width: 15,
            render: (p) => moneyCell(p.fee)
          },
          {
            title: 'НДС (20%), руб.',
            width: 15,
            render: (p) => moneyCell(p.vat)
          },
          {
            title: 'Выкупная стоимость с НДС, руб.',
            width: 15,
            render: (p) => (p.date === 'Итого' ? emptyCell : moneyCell(p.redemption))
          }
        ]
      })

      saveSheet(
        ws,
        `График платежей по ДЛ${deal?.contractNumber} от ${deal?.contractDate ? dateFormatter.format(new Date(deal?.contractDate)) : ''}.xlsx`,
        'Таблица с платежами'
      )
    } catch (e) {
      showPopup({ title: 'Ошибка', subtitle: 'Не удалось скачать график' })
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <button disabled={loading} className={c('text-labels-tertiary', className)} onClick={handleSaveSchedule}>
      {loading ? (
        <LoadingIcon className='flex-none animate-spin' width='20px' height='20px' />
      ) : (
        <ZipIcon className='hover:text-base-red' />
      )}
    </button>
  )
}

export default PaymentScheduleSaveBtn
