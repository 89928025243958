import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface VerificationStatusUpdatedEventProps extends BaseHistoryEventProps {
  data?: {
    status?: string
    fio?: string
  }
}

const verificationStateDict: Record<string, string> = {
  phone_verification: 'Проверка телефона',
  geolocation: 'Геолокация',
  person_verification: 'Проверка личности',
  questionnaire: 'Опросник',
  completed: 'Ожидает проверки',
  accepted: 'Проверен',
  rejected: 'Отказано'
}

const VerificationStatusUpdated: FC<{ status?: string; initiatedByUserId?: number; contact?: string }> = ({
  status,
  initiatedByUserId,
  contact
}) => {
  switch (status) {
    case 'phone_verification':
    case 'person_verification':
    case 'questionnaire':
      return (
        <span className='text-labels-tertiary'>
          Контакт <span className='text-base-black'>{contact}</span> на этапе{' '}
          <span className='text-base-black'>”{verificationStateDict[status]}”</span>
        </span>
      )
    case 'completed':
    case 'accepted':
    case 'rejected':
      return (
        <div>
          <div className='flex items-start gap-2'>
            <EventInitiatedBy userId={initiatedByUserId} />
            <span className='text-labels-tertiary'>сменил(а) статус проверки контакта</span>
          </div>
          <span>{`${contact ? contact + ' -' : ''} ”${status && verificationStateDict[status]}”`}</span>
        </div>
      )
    default:
      return null
  }
}

const VerificationStatusUpdatedEvent: FC<VerificationStatusUpdatedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <VerificationStatusUpdated status={data?.status} initiatedByUserId={initiatedByUserId} contact={data?.fio} />
    </div>
  )
}

export default VerificationStatusUpdatedEvent
