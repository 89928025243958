import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import { getDateFormattedString } from '../../../../utils/contractUtils'

export interface ComissionRewardCreatedEventProps extends BaseHistoryEventProps {
  data?: {
    id: number
    number: number
    documentDate: string | null
  }
}

const PaymentScheduleCreatedEvent: FC<ComissionRewardCreatedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <>
      {data?.number && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>
                создал(а) график платежей №{data.number} от{' '}
                {data.documentDate ? getDateFormattedString(new Date(data.documentDate)) : '"Не указано"'}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentScheduleCreatedEvent
