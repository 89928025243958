import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { useBoVerificationsSuspenseQuery, useUpdateContactMutation } from '../../../graphql/schema.tsx'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import { getDateFormattedString } from '../../../utils/contractUtils.ts'
import Input from '../../../components/Input.tsx'
import { IDocument } from '../../../components/DocumentManager/Document.tsx'
import SubmitButton from '../../../components/SubmitButton'
import Previewer from '../../../components/Preview/Previewer.tsx'

const EditDataProcessingConsentForm: FC<{
  contactId: string
  date?: string
  onDone: () => void
  docs?: IDocument[]
}> = ({ contactId, date, onDone, docs }) => {
  const { data } = useBoVerificationsSuspenseQuery({
    variables: { contactId: parseInt(contactId), states: ['completed', 'rejected', 'accepted'] }
  })
  const boVerification = data?.boVerifications[0]

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<{ date: string }>({
    defaultValues: {
      date: date ? new Date(date).toISOString().split('T').shift() : ''
    }
  })
  const [updateContact, { loading }] = useUpdateContactMutation()

  const onSubmit: SubmitHandler<{ date: string }> = async (data) => {
    if (loading || !contactId) return

    await updateContact({
      variables: {
        input: {
          id: contactId,
          dataProcessingConsentDate: new Date(data.date).toISOString()
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<{ date: string }>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[958px] p-12 lg:w-[1200px]'>
      <h1 className='mb-12 font-display text-h200'>Согласие на обработку персональных данных</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-2 gap-10'>
          <div className='grid grid-cols-1 gap-y-5'>
            {docs?.map((doc) => <Previewer key={doc._id} doc={doc} source='backend' />)}
          </div>
          <div>
            <table className='w-full'>
              <tbody>
                <tr className='group'>
                  <td className='w-80 px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Фамилия, имя, отчество
                  </td>
                  <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {boVerification?.personInfo?.fullName}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='w-80 px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Дата и место рождения
                  </td>
                  <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {getDateFormattedString(new Date(boVerification?.personInfo?.birthDate))},{' '}
                    {boVerification?.personInfo?.birthPlace}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='w-80 px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Адрес
                  </td>
                  <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {boVerification?.personInfo?.regAddress}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='w-80 px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Паспортные данные
                  </td>
                  <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {boVerification?.personInfo?.passportSeriesAndNumber},{' '}
                    {getDateFormattedString(new Date(boVerification?.personInfo?.passportIssueDate))}{' '}
                    {boVerification?.personInfo?.passportIssuedBy}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className='mt-24 w-full'>
              <tbody>
                <tr className='group'>
                  <td className='w-80 px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl'>
                    Дата согласия
                  </td>
                  <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl'>
                    <Input type='date' {...register('date', { required: true })} error={errors.date} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default EditDataProcessingConsentForm
