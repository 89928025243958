import { FC, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import downloadFile from '../../../utils/downloadFileServerless'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  isIP
} from '../../../utils/contractUtils'
import {
  useDealForActReportSuspenseQuery,
  useSourceByIdSuspenseQuery,
  VatKind,
  useCreateActReportPdfMutation
} from '../../../graphql/schema'
import { AddressData, CompanyData } from '../../../types/dadata'
import Input from '../../../components/Input'
import MaskedInput from '../../../components/MaskedInput'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import SubmitButton from '../../../components/SubmitButton/index'
import useNodes from '../../../hooks/useNodes'
import { Listbox } from '@headlessui/react'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import SelectedFilter from '../../../components/Filter/SelectedFilter'
import { Company, Signatory } from '../models'
import { LEADER_STATUSES_GENITIVE_MAP } from '../models/constants'
import getInclineFIO from '../../../utils/getInclineFIO'
import c from 'clsx'
import Suggestions from '../../../components/Suggestions.tsx'
import { useAddressSuggestions } from '../../../hooks/useSuggestions.ts'
import useThrottledState from '../../../hooks/useThrottledState.ts'
import SignatorySelectInput, { KimSignatory } from '../../../components/SignatorySelectInput.tsx'
import RequisiteInput from '../../../components/Requisites/RequisiteInput.tsx'

type Inputs = {
  agentContractN: string
  agentContractDate: string
  actReportN: string
  actReportDate: string
  category: string
  dateFrom: string
  dateTo: string
  signatoryFio: string
  signatoryStatus: string
  company: Company
  signatory: Signatory
  vat?: number
}

interface ActReportFormProps {
  dealId?: number
  sourceId?: number
}

const ActReportForm: FC<ActReportFormProps> = ({ dealId, sourceId }) => {
  const [loading, setLoading] = useState(false)
  const [suppliesNotSelected, setSuppliesNotSelected] = useState('')
  const [createActReport] = useCreateActReportPdfMutation()

  const { data } = useDealForActReportSuspenseQuery({ variables: { id: `${dealId}` } })
  const { data: sourceData } = useSourceByIdSuspenseQuery({ variables: { id: `${sourceId}` } })

  const deal = data?.deal
  const source = sourceData?.source
  const sourceCompany = source?.company

  const agentCommissionRewards = useNodes(deal?.agentCommissionRewards?.edges)
  const dealSupplies = useNodes(deal?.dealSupplies?.edges)
  const bankDetails = useNodes(sourceCompany?.bankDetails?.edges) || []
  const [selectedDealSupplies, setSelectedDealSupplies] = useState<typeof dealSupplies>()

  const companyDadata: CompanyData = useMemo(
    () => (sourceCompany?.dadata ? sourceCompany.dadata.data : {}),
    [sourceCompany?.dadata]
  )
  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)
  let leaderStatus =
    LEADER_STATUSES_GENITIVE_MAP.get(companyDadata?.management?.post?.toLowerCase()) ||
    companyDadata?.management?.post?.toLowerCase() ||
    ''
  leaderStatus = capitalizeFirst(leaderStatus)
  const leaderStatusGenitiveWithName = companyDadata?.management?.name
    ? (leaderStatus + ' ' + getInclineFIO(companyDadata?.management?.name)).trim()
    : ''

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      agentContractN: source?.agentContractNumber,
      agentContractDate: source?.agentContractDate
        ? new Date(source.agentContractDate).toISOString().split('T').shift()
        : '',
      actReportN: deal?.contractNumber ? `АОВ${deal.contractNumber}` : '',
      category: deal?.leasingSubjectCategories?.edges?.[0]?.node?.name || '',
      actReportDate: new Date().toISOString().split('T').shift(),
      signatoryFio: IP ? capitalizeFirstInWords(companyDadata?.name?.full) : companyDadata?.management?.name,
      signatoryStatus: IP
        ? sourceCompany?.fullWithOpf && getCompanyForm(sourceCompany?.fullWithOpf, companyName, true)
        : capitalizeFirst(companyDadata?.management?.post),
      signatory: KimSignatory,
      company: {
        formShort: sourceCompany?.shortWithOpf ? getCompanyForm(sourceCompany?.shortWithOpf, companyName) : '',
        form: sourceCompany?.fullWithOpf ? getCompanyForm(sourceCompany?.fullWithOpf, companyName, true) : '',
        name: companyName,
        requisites: {
          inn: sourceCompany?.inn || '',
          legalAddress: companyDadata.address?.value,
          ogrn: companyDadata.ogrn || '',
          kpp: !IP ? companyDadata.kpp : undefined,
          bankName: bankDetails?.length === 1 ? bankDetails[0]?.name : undefined,
          accountNumber: bankDetails?.length === 1 ? bankDetails[0]?.account : undefined,
          correspondentNumber: bankDetails?.length === 1 ? bankDetails[0]?.correspondentAccount : undefined,
          bik: bankDetails?.length === 1 ? bankDetails[0]?.bic : undefined
        },
        leaderStatus: !IP ? capitalizeFirst(companyDadata?.management?.post) : undefined,
        leaderStatusGenitiveWithName: !IP ? leaderStatusGenitiveWithName.trim() : undefined,
        leader: !IP
          ? {
              name: companyDadata?.management?.name || ''
            }
          : undefined
      }
    }
  })

  const addressQuery = watch('company.requisites.legalAddress')
  const [throttledAddressQuery] = useThrottledState(addressQuery, 500)
  const addressSuggestions = useAddressSuggestions(throttledAddressQuery)

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (loading || !deal?.contractNumber || !deal?.contractDate || !agentCommissionRewards?.length) return
    if (!selectedDealSupplies?.length) {
      setSuppliesNotSelected('Выберите поставку')
      return
    }

    setLoading(true)

    try {
      const createActReportResult = await createActReport({
        variables: {
          input: {
            ...data,
            company: {
              ...data.company,
              leaderStatusGenitiveWithName: data.company?.leaderStatusGenitiveWithName || undefined,
              requisites: {
                ...data.company.requisites,
                legalAddress: data.company.requisites.legalAddress || ''
              }
            },
            contractN: getFormattedContractNumber(deal.contractNumber),
            contractDate: getDateFormattedString(new Date(deal.contractDate.split('T').shift() as string)),
            contractCompany: `${deal?.customerCompany?.shortWithOpf}`,
            amount: agentCommissionRewards.reduce((acc, commission) => acc + commission?.amount, 0),
            supplies: selectedDealSupplies.map((supply) => supply?.supplierCompany?.shortWithOpf).join(', '),
            suppliesAmount: selectedDealSupplies.reduce((acc, supply) => acc + supply?.amount, 0),
            vat:
              source?.company?.vatKind === VatKind.Osno ? 20 : source?.company?.vatKind === VatKind.Usn ? 0 : undefined
          }
        }
      })

      const actReportUrl = createActReportResult.data?.createActReport?.url
      if (!actReportUrl) throw new Error('Не удалось получить ссылку на акт-отчет')

      await downloadFile(
        actReportUrl,
        `${getDateFormattedInDocumentName(new Date())} Акт-отчет №${data.actReportN} от ${getDateFormattedString(
          new Date(data.actReportDate)
        )}.pdf`
      )
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Генерация акта-отчета</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <div className='grid grid-cols-2 gap-16'>
            <Input
              readOnly
              label='Агентский договор'
              type='string'
              placeholder='АД220923-01'
              {...register('agentContractN', {
                required: true
              })}
            />
            <Input readOnly label='Дата договора' type='date' {...register('agentContractDate', { required: true })} />
          </div>
          <div className='grid grid-cols-2 gap-16'>
            <MaskedInput
              label='Акт-отчет'
              type='string'
              placeholder='АОВ220923-01'
              mask='АОВ000000-00'
              {...register('actReportN', {
                required: true
              })}
            />
            <Input label='Дата акта' type='date' {...register('actReportDate', { required: true })} />
          </div>
          <Input label='Предмет лизинга' type='text' {...register('category', { required: true })} />
          <div className='grid grid-cols-2 gap-16'>
            <Input label='Период с' type='date' {...register('dateFrom', { required: true })} />
            <Input label='До' type='date' {...register('dateTo', { required: true })} error={errors.dateTo?.message} />
          </div>
          <Input
            label='Кто отвечает за компанию'
            placeholder='Генерального директора Ивана Ивановича Иванова'
            type='string'
            {...register('company.leaderStatusGenitiveWithName')}
            error={errors.company?.leaderStatusGenitiveWithName}
          />
          <Input label='Подписант (ФИО)' type='text' {...register('signatoryFio', { required: true })} />
          <Input label='Должность подписанта' type='text' {...register('signatoryStatus', { required: true })} />
          <Controller
            name='company.requisites'
            rules={{ validate: (value) => (!value?.bankName ? 'Обязательное поле' : true) }}
            control={control}
            render={({ field, fieldState }) => (
              <RequisiteInput
                onChange={(value) => field.onChange(value)}
                value={field.value}
                error={fieldState.error}
                bankDetails={bankDetails}
              />
            )}
          />
          <Suggestions<AddressData>
            suggestions={addressSuggestions?.map((s: AddressData) => ({
              key: s._id,
              title: s.value,
              payload: s
            }))}
            select={(s) =>
              setValue('company.requisites.legalAddress', s.payload?.value || '', {
                shouldValidate: true
              })
            }
          >
            <Input
              label='Юридический адрес'
              type='text'
              autoComplete='off'
              placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
              {...register('company.requisites.legalAddress', { required: true })}
              error={errors.company?.requisites?.legalAddress}
            />
          </Suggestions>
          <div className='relative'>
            <Listbox
              multiple
              value={selectedDealSupplies}
              onChange={(data) => {
                setSelectedDealSupplies(data)
                setSuppliesNotSelected('')
              }}
            >
              <Listbox.Button className='w-full text-left'>
                <Input
                  readOnly
                  placeholder={
                    selectedDealSupplies?.length ? `Выбрано: ${selectedDealSupplies?.length}` : 'Выберите поставщика'
                  }
                  label='Поставка'
                  error={suppliesNotSelected}
                />
              </Listbox.Button>
              <Listbox.Options className='absolute z-50 mt-5 max-h-100 w-full overflow-y-auto rounded-xl bg-white-0 py-5 shadow-xs scrollbar-hide'>
                {dealSupplies.map((supply) => (
                  <Listbox.Option
                    key={supply._id}
                    className={c(
                      'w-full cursor-pointer px-10 py-5 text-left hover:bg-grayscale-450 active:bg-grayscale-400',
                      !supply?.amount && 'pointer-events-none'
                    )}
                    value={supply}
                  >
                    <div className='flex items-center justify-between'>
                      <div className='flex flex-col gap-2 text-p200'>
                        <p className={c('text-grayscale-0', !supply?.amount && 'text-grayscale-250')}>
                          {supply?.supplierCompany?.shortWithOpf}
                        </p>
                        <p className={c('text-grayscale-150', !supply?.amount && 'text-grayscale-250')}>
                          Стоимость: {formatMoneyDecimal((supply?.amount || 0) / 100)}
                        </p>
                      </div>
                      {selectedDealSupplies?.find((selected) => selected?._id === supply?._id) && (
                        <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                          <TickIcon />
                        </div>
                      )}
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
            {!!selectedDealSupplies?.length && (
              <div className='mt-8 flex flex-wrap gap-2'>
                {selectedDealSupplies?.map((supply) => (
                  <SelectedFilter
                    onRemove={() =>
                      setSelectedDealSupplies((supplies) => supplies?.filter((s) => s._id !== supply._id))
                    }
                  >
                    {supply?.supplierCompany?.shortWithOpf}
                  </SelectedFilter>
                ))}
              </div>
            )}
          </div>
          <Controller
            name='signatory'
            control={control}
            render={({ field }) => (
              <SignatorySelectInput
                selected={field.value}
                onChange={(selected) => field.onChange(selected)}
                label='Подписант Фера'
                error={errors?.signatory?.message}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>
          <FileIcon className='mr-5' />
          Сгенерировать
        </SubmitButton>
      </form>
    </section>
  )
}

export default ActReportForm
